import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Form, FormGroup, Input, Row} from "reactstrap";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import {useDispatch, useSelector} from "react-redux";
import {getSalesReportData, selectSalesReport, setTotalAmount} from "./store/salesReportSlice";
import withReducer from "../../../store/withReducer";
import reportReducer from "../index";
import DatePicker from "react-datepicker";
import {getSellerSettings, setSettings} from "../../seller/store/sellerSlice";
import Select from "react-select";
import moment from "moment";

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

const dataMenu = [
    {
        type: 0,
        text: 'Sales Report',
    }
];

const tableColumns = [
    {
        name: '#',
        selector: row => row.order,
        sortable: true,
        center: false,
        width:"7%"
    }, {
        name: 'Date',
        selector: row => row.date,
        sortable: true,
        center: false
    },
    {
        name: 'SKU',
        selector: row => row.sku,
        sortable: true,
        center: false,
    },
    {
        name: 'Product',
        selector: row => row.product,
        sortable: true,
        center: false,
    },
    {
        name: 'Qty',
        selector: row => row.quantity,
        sortable: true,
        center: false,
        width:"7%"
    },
    {
        name: 'Price (£)',
        selector: row => row.price,
        sortable: true,
        center: false,
        width:"10%"
    },
    {
        name: 'Price Excl Vat (£)',
        selector: row => row.price_exc_vat,
        sortable: true,
        center: false,
        right:true,
        width:"15%"
    }
];

const SalesReport = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const start_date=queryParams.get("start_date");
    const end_date=queryParams.get("end_date");

    const dispatch = useDispatch();
    const [page,setPage]=useState(1);
    const [loading, isSetLoading] = useState(false);
    const [tableLoading, isSetTableLoading] = useState(false);
    const salesReportState = useSelector(({report}) => report.salesReport);
    const salesReport = useSelector(selectSalesReport);
    const TodayDate=new Date();
    const sevenDays=new Date().setDate(TodayDate.getDate()-30);
    const [pageSize, setPageSize] = useState(10)
    const sellerState = useSelector(({seller}) => seller.seller);


    const [agent, setAgent] = useState(null);

    const [dateRange, setDateRange] = useState([ sevenDays,TodayDate]);

    const [startDate, endDate] = dateRange;

    useEffect(() => {
        getData();
    }, [startDate, endDate,agent,page, pageSize,dispatch]);

    const pagination = (e) => {
        setPageSize(e)
    }


    const getData = () =>{
        if(startDate!==null && endDate!==null){
            isSetTableLoading(true);
            const data= {
                agent,
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            }
            dispatch(getSalesReportData(data)).then(res => {
                if(!res.error){
                    isSetLoading(false);
                }
                isSetTableLoading(false);
            });
        }
    }

    useEffect(() => {
        dispatch(getSellerSettings()).then(res => {
            if (!res.error) {
                const results={
                    settings:res.payload,
                    id:null
                }
                dispatch(setSettings(results));
            }
        });
    },[dispatch]);


    return (
        <Fragment>
            {loading ? <></>: <>
                <Breadcrumb title="Sales Report" data={dataMenu}/>
                <Container fluid={true} className="product-wrapper">
                    <div className="product-grid">
                        <div className="feature-products">
                            <Row>
                                <Col sm="6">
                                    <div className="dashboard-datepicker">
                                        <DatePicker
                                            selectsRange={true}
                                            className="form-control hide-border-control"
                                            dateFormat="dd/MM/yyyy"
                                            startDate={startDate}
                                            maxDate={TodayDate}
                                            endDate={endDate}
                                            styles={customStyles}
                                            onChange={(update) => {
                                                setDateRange(update);
                                            }}
                                            placeholderText="Search by Date Range"
                                            isClearable={false}
                                        />
                                    </div>
                                    <Row className="mt-4">
                                        <Col sm="6">
                                            <label>Total Amount (£): {salesReportState.totalAmount}</label>
                                        </Col>
                                        <Col sm="6">
                                            <label>Total Orders : {salesReportState.salesReportTableRow}</label>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col sm="6">
                                    <Select
                                        placeholder="Agent/Sales Person"
                                        className="select2-filter"
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        isClearable
                                        cacheOptions
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        styles={customStyles}
                                        options={sellerState.settings.agents}
                                        onChange={(e) => {
                                            console.log(e)
                                            setAgent(e?e.id:null)
                                        }}

                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-4" sm="12">
                                    <Card>
                                        <CardBody>
                                            <div className="table-responsive product-table">
                                                <CommonDataTable
                                                    headerColumns={tableColumns}
                                                    gridData={salesReport}
                                                    paginationServer
                                                    paginationTotalRows={salesReportState.salesReportTableRow}
                                                    noAction
                                                    onChangePage={page => setPage(page)}
                                                    onChangeRowsPerPage={pagination}
                                                />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </div>

                </Container>
            </>}
        </Fragment>
    );
}

export default withReducer([{report: reportReducer}]) (SalesReport);