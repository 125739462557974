import {Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment} from "react";
import Select from "react-select";

export const CustomerServiceContactDetails = (props) => {
    const {sellerState,errors,register,trigger,setValue,getValues}=props;
    return (
        <Fragment>
            <Row>
                <Col md={12}>
                    <FormGroup>
                        <Label className="form-label">Full Name</Label>
                        <input name="customerFullName" type="text" className={`form-control ${errors.customerFullName ? 'is-invalid' : ''}`} placeholder="John Doe" {...register('customerFullName')}  />
                        <div className="invalid-feedback">{errors.customerFullName?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>
                        <Label className="form-label">Mobile / Landline Number</Label>
                        <input name="customerMobileNumber" type="text" className={`form-control ${errors.customerMobileNumber ? 'is-invalid' : ''}`} placeholder="2085972744" {...register('customerMobileNumber')}  />
                        <div className="invalid-feedback">{errors.customerMobileNumber?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>
                        <Label className="form-label">Email</Label>
                        <input name="customerEmail" type="email" className={`form-control ${errors.customerEmail ? 'is-invalid' : ''}`} placeholder="john.doe@citygoddess.co.uk" {...register('customerEmail')}  />
                        <div className="invalid-feedback">{errors.customerEmail?.message}</div>
                    </FormGroup>
                </Col>
            </Row>
        </Fragment>
    );
};