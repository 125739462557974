import React, {Fragment, useEffect, useState} from "react";
// import Breadcrumb from "../../../../../layout/breadcrumb";
import Breadcrumb from '../../../../layout/breadcrumb';
import {Card, CardBody, Col, Container, FormGroup, Input, Label, Nav, Row, Table} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import withReducer from "../../../../store/withReducer";
// import useNavigate from 'react-router-dom';
import lookBookReducer from "../../store";

import PreLoader from "../../../../share-components/ui/preLoader";
import productReducer from "../../store";
import { getLookBooks, selectLookBook, setLookBookData } from "../../store/lookBookSlice";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import { mapLookBookResponseToTable } from "../../../../utils/mapper";


const LookBook = (props) => {
    const OrderFormat = row => <a>{row.orderName}</a>;
    const dispatch = useDispatch();
    // let history = useHistory();
    const [page, setPage] = useState(1);
    const [selectedRow, setSelectedRow] = useState(false);
    const [loading, isSetLoading] = useState(true);
    const [orderData, setOrderData] = useState([]);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);
    const [status, setStatus] = useState(null);
    const [financialStatus, setFinancialStatus] = useState(null);
    const [paymentType, setPaymentType] = useState(null);
    const lookBookState = useSelector(({lookBook}) => lookBook.lookBook);
    const lookBookData = useSelector(selectLookBook);
    // const orderReduxData = useSelector(selectOrder);
    const [country, setCountry] = useState(null);
    useEffect(() => {
        setOrderData(mapLookBookResponseToTable(lookBookData));
    }, [lookBookData]);
console.log(orderData);
const dataMenu = [
    {
        type: 1,
        text: 'Products',
        link: '/product'
    },
    {
        type: 0,
        text: "Look Book"
    }
];
let tableColumns = [
    {
        name: 'Title',
        selector: row => row.title,
        sortable: true,
        center: false,
    },
    {
        name: 'Sub Title',
        selector: row => row.subTitle,
        sortable: true,
        center: false,
    },
    {
        name: 'Heading',
        selector: row => row.heading,
        sortable: true,
        center: false,
    },
    {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
        center: false,
    },
    {
        name: 'Contact name',
        selector: row => row.contactName,
        sortable: true,
        center: false,
    },
    {
        name: 'Template',
        selector: row => row.template,
        sortable: true,
        center: false,
    },
    {
        name: 'Created At',
        selector: row => row.created_at,
        sortable: true,
        center: false,
    },
];

const conditionalRowStyles = [

    {
        when: row => row.isPackingSlip,
        style: row => ({
            backgroundColor: '#FFD0D0',
        }),
    },
    {
        when: row => row.isPackingTicket,
        style: row => ({
            backgroundColor: '#AAB7F9',
        }),
    },
    {
        when: row => (row.isPackingTicket && row.isPackingSlip),
        style: row => ({
            backgroundColor: '#BEF781'
        }),


    },

];

const openLookBookView = (row) => {
    window.open(`/look-book/product-look-book/${row.id}`, "_blank");

}

tableColumns=[
        ...tableColumns,
        {
            name: 'Action',
            selector: (row) => <><a onClick={() => openLookBookView(row)} className="ml-1 btn btn-sm btn-primary p-2"><i className="fa fa-file-pdf-o"></i> Download
            </a>
                </>,
            sortable: true,
            center: false,
        }
    ];


    useEffect(() => {
        getData();
    }, [page]);

    const getData = () => {
        const data = {
            page: page,
            pageSize: 10,
        }

        dispatch(getLookBooks(data)).then(res => {
            if (!res.error) {
                // setLookBookData(res.payload.data);
                isSetLoading(false);
                
            }
        });

    }

    console.log(lookBookState);

    return (
        <Fragment>
            {loading ? <>
                    <PreLoader></PreLoader>
                </>
                : <>
            <Breadcrumb title="Look Book" data={dataMenu}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <div className="table-responsive">
                                    <CommonDataTable
                                                headerColumns={tableColumns}
                                                gridData={orderData}
                                                paginationServer
                                                paginationTotalRows={lookBookState.tableRow}
                                                paginationPerPage={10}
                                                paginationComponentOptions={{
                                                    noRowsPerPage: true
                                                }}
                                                conditionalRowStyles={conditionalRowStyles}
                                                onChangePage={page => setPage(page)}
                                                noAction={true}
                                            />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
                </>}
        </Fragment>
    );
}

export default withReducer([{lookBook: lookBookReducer}])(LookBook);