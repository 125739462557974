import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Breadcrumb from '../../../../../layout/breadcrumb'
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody, FormGroup, Label, Form, Button,

} from 'reactstrap';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {useHistory, useParams} from "react-router-dom";
import PreLoader from "../../../../../share-components/ui/preLoader";
import { baseUrlAsync } from '../../../../../utils/api';
import pdfToolBar from '../../../../../share-components/pdf/pdfToolBar';
import { closeLookBookDialog, productLookBook } from '../../../store/prodcutSlice';
import { downloadLookBookApi } from '../../../../../services/manage-product/productService';


const dataMenu = [
    {
        type: 1,
        text: 'Products',
        link: '/product'
    },
    {
        type: 0,
        text: "Look Book"
    }
];

const LookBookPdf = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [isButtonVisible, setIsButtonVisible] = useState(false);

    const productState = useSelector(({product}) => product.product.productSettings);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar: eProps => {
            return pdfToolBar(eProps, pdfDownload);
        }
    });
    const pdfDownload = async () => {
		// const pdfResponse = await productLookBook(id);
        const pdfResponse = await downloadLookBookApi(id);
        const url = window.URL.createObjectURL(new Blob([pdfResponse.response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Look_Book${(new Date().toJSON().slice(0,10))}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
        dispatch(closeLookBookDialog());

	};
    
    return (
        <Fragment>

            <Container fluid={true}>
                {loading ? <>
                    <PreLoader></PreLoader>
                </> : <Row>
                    <Breadcrumb title='Look Book' data={dataMenu}/>
                    <Col sm="12">
                        <div className="rpv-core__viewer"
                        >
                            <div
                                style={{
                                    // height: "1000px",
                                    // width: "100%",
                                    marginLeft: "auto",
                                    marginRight: "auto"
                                  }}
                            >
                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                                    <div style={{ height: '1000px', flex: 1,
                    overflow: 'hidden' }}>
                                        <Viewer
                                            httpHeaders={{
                                                Authorization: `Bearer ${localStorage.getItem('token')}`
                                            }}
                                            fileUrl={`${baseUrlAsync}/look-book/product-look-book/${id}`}
                                            plugins={[defaultLayoutPluginInstance]}
                                        />
                                    </div>
                                </Worker>
                            </div>
                        </div>
                    </Col>
                </Row>}
            </Container>


        </Fragment>
    );

}

export default LookBookPdf;