import React, {useState} from 'react';
import {Button, Col, Container, Form, FormGroup, Input, Label, Row} from 'reactstrap'
import {useHistory, withRouter} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import {resetPasswordApi} from "../services/auth";
import {toast} from "react-toastify";

const defaultValues = {
  password: ''
}

const schema = yup.object().shape({
  password: yup
      .string()
      .required('Please enter your password.')
      .min(8, 'Password is too short - should be 8 chars minimum.'),
  passwordConfirm: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match')
});

const Forgetpwd = (props) => {

  const [togglePassword, setTogglePassword] = useState(false);
  const [password, setPassword] = useState("");

  const {handleSubmit, register, formState: {errors}, setValue, control} = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  });
  let history = useHistory();

  let urlToken = window.location.search;
  urlToken = urlToken.split("=");
  const finalToken = urlToken[2];
  const emailVal = urlToken[1];
  const tempEmail = emailVal.split("&");
  const email = tempEmail[0];
  console.log(finalToken,email)

  const redirectToLogin = () => {
    history.push('/login')
  }

  const onSubmit = async data => {
    const postData = new URLSearchParams({
      password: data.password,
      password_confirmation: data.passwordConfirm,
      token: finalToken,
      email: email,
    });
    await resetPasswordApi(postData);
    history.push('/login')
    toast.success("Password change successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  return (
      <Container fluid={true}>
        <Row>
          <Col xs="12">
            <div className="login-card">
              <div className="login-main">
                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                  <h6 className="mt-4">{"Create Your Password"}</h6>
                  <FormGroup>
                    <Label className="col-form-label">New Password</Label>
                    <input name="password" type={togglePassword ? "text" : "password"}  {...register('password')}
                           className={`form-control ${errors.password ? 'is-invalid' : ''}`}/>
                    <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span
                        className={togglePassword ? "" : "show"}></span></div>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">Retype Password</Label>
                    <input name="passwordConfirm" type="password"  {...register('passwordConfirm')}
                           className={`form-control ${errors.passwordConfirm ? 'is-invalid' : ''}`}/>
                  </FormGroup>
                  <FormGroup className="mb-0">
                    <div className="checkbox ml-3">
                      <Input id="checkbox1" type="checkbox"/>
                    </div>
                    <Button color="primary" className="btn-block" type="submit">Submit</Button>
                  </FormGroup>
                  <p className="mt-4 mb-0">{"Already have an password?"}<a onClick={redirectToLogin} className="ml-2"
                                                                           href="#javascript">SignIn</a></p>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
  );
}

export default withRouter(Forgetpwd);