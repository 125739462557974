import React, {Fragment, useEffect, useState} from 'react';
import {Container,Row,Col,Card,CardHeader,CardBody} from 'reactstrap';
import {Redirect, Route, Switch} from "react-router-dom";
import Signin from "./signin";

import Callback from "./callback";
import App from "../components/app";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {routes} from "../route";
import {classes} from "../data/layouts";
import ConfigDB from "../data/customizer/config";
import withReducer from "../store/withReducer";
import themeReducer from "../layout/store";
import authReducer from "../redux/index";
import {connect, useSelector} from "react-redux";
import AuthGuard from "./AuthGuard";
import Forgetpwd from "./forgetpwd";
import ForgotPwMail from "./forgotPwMail";


const  Routes = ({userData}) => {
    const [anim, setAnim] = useState("");
    const animation = localStorage.getItem("animation") || ConfigDB.data.router_animation || 'fade'
    const abortController = new AbortController();
    const [currentUser, setCurrentUser] = useState(false);
    const [authenticated,setAuthenticated] = useState(false)
    const jwt_token = localStorage.getItem('token');
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const layout = localStorage.getItem('layout') ||  Object.keys(defaultLayoutObj).pop();
    console.log(userData);



    useEffect(() => {


        setAnim(animation)
        // firebase_app.auth().onAuthStateChanged(setCurrentUser);
        setAuthenticated(JSON.parse(localStorage.getItem("authenticated")))
        console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
        console.disableYellowBox = true;
        console.log(currentUser,jwt_token,authenticated);

        // return function cleanup() {
        //     abortController.abort();
        // }

        // eslint-disable-next-line
    }, []);
    return (
        <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/login`} component={AuthGuard(Signin)} />
            <Route exact path={`${process.env.PUBLIC_URL}/change-password`} component={AuthGuard(Forgetpwd)} />
            <Route exact path={`${process.env.PUBLIC_URL}/forget-password-email`} component={AuthGuard(ForgotPwMail)} />
            <Route  path={`${process.env.PUBLIC_URL}/callback`} render={() => <Callback/>} />

            {userData && userData.email  ?

                <App>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                        return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />)
                    }} />
                    <TransitionGroup>
                        {routes.map(({ path, Component }) => (
                            <Route  key={path}  exact  path={`${process.env.PUBLIC_URL}${path}`}>
                                {({ match }) => (
                                    <CSSTransition
                                        in={match != null}
                                        timeout={100}
                                        classNames={anim}
                                        unmountOnExit>
                                        <div><Component/></div>
                                    </CSSTransition>
                                )}
                            </Route>

                        ))}
                    </TransitionGroup>

                </App>
                :
                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
            }

        </Switch>
    );
}
function mapStateToProps({ authReducer }) {
    console.log(authReducer)
    return {
        userData: authReducer.data
    };
}
export default  connect(mapStateToProps)(Routes) ;