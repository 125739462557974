import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import Breadcrumb from '../../../layout/breadcrumb'
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Media, Nav, NavItem, NavLink, TabContent, TabPane, Form, CardFooter
} from 'reactstrap'
import {PrimaryDetails} from "./form/primaryDetails";
import {defaultValues,schema} from "./settings";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {UpdateSellerForm} from "./form/updateSellerForm";
import {UpdateSellerNavItems} from "./updateSellerNavItems";
import {useDispatch, useSelector} from "react-redux";
import {
    getSeller,
    getSellerSettings, setSellerBanners,
    setSellerBrands,
    setSellerImages,
    setSettings,
    updateSeller
} from "../store/sellerSlice";
import {mapSellerUpdateForm} from "../../../utils/mapper";
import './custom-style.css'
import PreLoader from "../../../share-components/ui/preLoader";
import Lightbox from "react-image-lightbox";

const UpdateSeller = (props) => {
    const { id } = useParams();
    const submitBtnRef = useRef();
    const dataMenu = [
        {
            type: 1,
            text: 'Sellers',
            link: '/seller'
        },
        {
            type: 0,
            text: "Edit Seller"
        }
    ];

    const [VerticleTab,setVerticleTab]=useState('1');
    const [isPremium,setIsPremium]=useState(false);
    const [isTaxChargeable,setIsTaxChargeable]=useState(false);
    const [loading, isSetLoading] = useState(true);
    const dispatch = useDispatch();
    const sellerState = useSelector(({ seller }) => seller.seller);

    const [images,setImage] = useState([]);
    const initilindex = {index:0,isOpen:false}
    const[photoIndex,setPhotoIndex] = useState(initilindex);

    const onMovePrev = () => {
        const prev = (photoIndex.index + images.length - 1) % images.length
        setPhotoIndex({...photoIndex,index:prev})
    }

    const  onMoveNext = () => {
        const next = (photoIndex.index + 1) % images.length
        setPhotoIndex({...photoIndex,index:next})
    }

    const [sellerSinceDate, setSellerSinceDate] = useState(new Date());

    const { handleSubmit, formState, reset, register,trigger,setValue,getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    const { errors } = formState;
    const onSubmitForm = (data) => {
        const set={
            id:sellerState.id,
            results:data
        }
        submitBtnRef.current.disabled=true;
        dispatch(updateSeller({ ...set })).then(res => {
            if (!res.error) {
                setVerticleTab((parseInt(VerticleTab)+1).toString());
            }
            submitBtnRef.current.disabled=false;
        });
    };

    useEffect(() => {
        dispatch(getSellerSettings()).then(res => {
            if (!res.error){
                const results={
                    settings:res.payload,
                    id:id
                }
                dispatch(setSettings(results));
            }
            if(id){
                dispatch(getSeller(id)).then(sellerRes => {
                    if (!sellerRes.error){
                        reset(mapSellerUpdateForm(sellerRes.payload));
                        setValue('storeDescription',sellerRes.payload.storeDescription);
                        setValue('sellerDescription',sellerRes.payload.sellerDescription);
                        setValue('otherInformation',sellerRes.payload.otherInformation);
                        setSellerSinceDate(new Date(sellerRes.payload.sellerSince));
                        setIsPremium(sellerRes.payload.isPremium);
                        setIsTaxChargeable(sellerRes.payload.chargeTax);
                        setValue('otherPolicy',sellerRes.payload.otherPolicy);
                        dispatch(setSellerImages(sellerRes.payload.seller_images));
                        dispatch(setSellerBrands(sellerRes.payload.seller_brands));
                        dispatch(setSellerBanners(sellerRes.payload.seller_banners));
                        setValue('returnCancellationPolicy',sellerRes.payload.returnCancellationPolicy);
                        isSetLoading(false);
                    };
                });
            }

        });
    },[dispatch]);

    useEffect(() => {
        if(sellerState.sellerBanners){
            const data=[];
            if(sellerState.sellerBanners){
                sellerState.sellerBanners.map((item, i) =>{
                    if(item.path){
                        data.push(item);
                    }
                })
                setImage(data);
            }
        }

    },[sellerState.sellerBanners])


    return (
        <Fragment>
            {loading ?
                <>
                    <PreLoader></PreLoader>
                </>
                :
                <>
            <Breadcrumb title={"Edit Seller - "+getValues('name')} data={dataMenu}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Form onSubmit={handleSubmit(onSubmitForm)}>
                            <CardBody>
                                <Row>
                                    <Col sm="3" xs="12" className="border-right">
                                        <UpdateSellerNavItems VerticleTab={VerticleTab} setVerticleTab={setVerticleTab} errors={errors}  />
                                    </Col>
                                    <Col sm="9" xs="12">
                                        <UpdateSellerForm
                                                          sellerSinceDate={sellerSinceDate} setSellerSinceDate={setSellerSinceDate} isPremium={isPremium} setIsPremium={setIsPremium}
                                                          sellerState={sellerState} errors={errors} register={register} trigger={trigger} setValue={setValue} isTaxChargeable={isTaxChargeable}
                                                          setIsTaxChargeable={setIsTaxChargeable} getValues={getValues} VerticleTab={VerticleTab}setPhotoIndex={setPhotoIndex} photoIndex={photoIndex}  />
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <Col className="col-md-4" style={{color:"red"}}>
                                        {errors.name?"Detail Name Error Found":""}
                                    </Col>
                                    {(VerticleTab != 2 && VerticleTab != 8 && VerticleTab != 9) ?
                                        <Col className="col-md-2 offset-md-10">
                                            <button ref={submitBtnRef} type="submit" className="btn btn-primary btn-block">Save</button>
                                        </Col>:""
                                    }
                                </Row>
                            </CardFooter>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
                    {photoIndex.isOpen && (
                        <Lightbox
                            mainSrc={images[photoIndex.index].path}
                            nextSrc={images[(photoIndex.index + 1) % images.length].path}
                            prevSrc={images[(photoIndex.index + images.length - 1) % images.length].path}
                            imageTitle={photoIndex.index + 1 + "/" + images.length}
                            onCloseRequest={() => setPhotoIndex({ ...photoIndex,isOpen:false})}
                            onMovePrevRequest={onMovePrev}
                            onMoveNextRequest={onMoveNext}
                        />
                    )}
                </>}
        </Fragment>
    );

};

export default UpdateSeller;


