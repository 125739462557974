import API from "../../utils/api";


export const getProductsApi = async (data) => {
    const response = await API.get(`product`,{
        params: data,
    });
    return { response };
};
export const getProductSetting = async (data) => {
    const response = await API.get(`product/get-product-settings`,{
        params: data,
    });
    return { response };
};

export const getProductCreateSetting = async (data) => {
    const response = await API.get(`product/get-product-create-settings`,{
        params: data,
    });
    return { response };
};

export const addProductApi = async data => {
    const response = await API.post('product', data);
    return { response };
};

export const getProductApi = async (id) => {
    const response = await API.get(`product/`+id+'/edit');
    return { response };
};
export const getProductViewApi = async (id) => {
    const response = await API.get(`product/`+id);
    return { response };
};
export const updateProductApi = async (data, id) => {
    const response = await API.put(`product/${id}`, data);
    return { response };
};

export const getSubCategoryByCategoryId = async (id) => {
    const response = await API.get(`product/get-sub-category/`+id);
    return { response };
};
export const addProductImageApi = async (id,data) => {
    const response = await API.post('product/upload-image/'+id, data,{'content-type': 'multipart/form-data'});
    return { response };
};

export const deleteProductApi = async ( id) => {
    const response = await API.delete(`product/delete-image/${id}`);
    return { response };
};

export const sortProductImageApi = async (id,data) => {
    const response = await API.post('product/sort-image/'+id, data);
    return { response };
};

export const bulkProductApi = async (data) => {
    const response = await API.post('product/bulk-product-action', data);
    return { response };
};

export const bulkSalesOfferApi = async (data) => {
    const response = await API.post('product/bulk-sales-offer-update', data);
    return { response };
};

export const updateProductImageAltTextApi = async (id,data) => {
    const response = await API.put('product/update-image-alt-text/'+id, data);
    return { response };
};
export const sellerAutoSuggestionApi = async (text) => {
        const response = await API.get('product/seller-auto-suggestion', {
        params: text,
    });
    return { response };
};

export const sellerBrandSuggestionApi = async (text) => {
    console.log(text);
    const response = await API.get('product/brand-auto-suggestion', {
        params: text,
    });
    return { response };
};
export const taxonomySuggestionApi = async (text) => {
    const response = await API.get('product/taxonomy-auto-suggestion', {
        params: text,
    });
    return { response };
};

export const addProductLookBookApi = async data => {
    const response = await API.post('look-book/store-product-look-book', data);    
    return { response };
};

export const downloadLookBookApi = async (id) => {
    const response = await API.get(`look-book/product-look-book/${id}`, {'content-type': 'application/pdf','responseType': 'blob'});
    return { response };
};

export const checkLookBookTitleApi = async (data) => {
    const response = await API.get('look-book/check-look-book_title', {
        params: data,
    });
    return { response };
};

export const getLookBookApi = async (data) => {
    const response = await API.get(`look-book/look-book-view`,{
        params: data,
    });
    return { response };
};

export const getCurrencyRateApi = async (price, code) => {
    const response = await API.get(`look-book/currency-convert/${price}/${code}`);
    return { response };
};