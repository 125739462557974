import React, {Fragment, useEffect, useRef, useState} from "react";
import {Badge, Card, CardBody, Col, FormGroup, Input, Label, Media, Alert} from "reactstrap";
import ImageUploader from 'react-images-upload';
import {openNewDialog} from "../../../../manage-user/store/userSlice";
import {Button, Row} from "react-bootstrap";
import DropZone from "../../../../../share-components/upload/dropzone";
import {useDispatch} from "react-redux";
import dragula from 'react-dragula';
import {
    addProductImage,
    deleteProductImage,
    sortProductImage,
    updateProductImageAlt
} from "../../../store/prodcutSlice";
import {get} from "react-hook-form";
import {getFileExtension} from "../../../../../utils/common";


import ReactImageVideoLightbox from "react-image-video-lightbox";

const ProductImage = (props) => {
    const {productState, setValue, trigger, getValues} = props;
    const dispatch = useDispatch();
    let imageAltRef = useRef([]);
    //const roleRegister = register("role", { required: true });
    const [image, setimage] = useState({pictures: []})
    const [imagePreloader, setImageLoader] = useState(false);
    const [isDragInit, setIsDragInit] = useState(false);
    const [viewFile, setViewFile] = useState([]);
    const [dragulaKey, setDragulaKey] = useState(0)

    const onDrop = (pictureFiles, pictureDataURLs) => {
        setimage({
            ...image, pictureFiles
        });
    }
    const onChangeFile = e => {
        if (e.length > 0) {
            setImageLoader(true);
            dispatch(addProductImage({
                id: getValues('id'),
                image: e,
                shopify_product_id: getValues('shopify_product_id')
            })).then(res => {
                if (!res.error) {
                    setValue('images', res.payload);
                    trigger('images');
                    setDragulaKey(dragulaKey+1);
                    setImageLoader(false);
                }
                ;
            });
        }
    };

    let dragulaDecoratorRight = (componentBackingInstance) => {
        if (componentBackingInstance) {
            if (!isDragInit) {
                let imageContainer = dragula([componentBackingInstance], {
                    isContainer: function (el) {
                        return false; // only elements in drake.containers will be taken into account
                    },
                    moves: function (el, source, handle, sibling) {
                        return true; // elements are always draggable by default
                    },
                    accepts: function (el, target, source, sibling) {
                        return true; // elements can be dropped in any of the `containers` by default
                    },
                    invalid: function (el, handle) {
                        return false; // don't prevent any drags from initiating by default
                    },
                });
                imageContainer.on('drop', (el, target, source, sibling) => {
                    const cardOrder = source.childNodes;
                    let imageIds = [];
                    cardOrder.forEach(item => {
                        imageIds.push(item.getAttribute('data-id'))
                    });
                    dispatch(sortProductImage({id: getValues('id'), image_ids: imageIds}));
                });
                setIsDragInit(true);
            }
        }
    };

    const deleteImage = (item) => {
        dispatch(deleteProductImage(item.id)).then(res => {
            if (!res.error) {
                setValue('images', res.payload);
                trigger('images');
                setDragulaKey(dragulaKey+1);
                setIsDragInit(false);
            }
        });
    }
    const initilindex = {index: 0, isOpen: false}
    const [photoIndex, setPhotoIndex] = useState(initilindex)

    const onMovePrev = () => {
        const prev = (photoIndex.index + getValues('images').length - 1) % getValues('images').length
        setPhotoIndex({...photoIndex, index: prev})
        imageAltRef.current[photoIndex.index].value = getValues('images')[prev].image_alt;
    }
    const onMoveNext = () => {
        const next = (photoIndex.index + 1) % getValues('images').length
        setPhotoIndex({...photoIndex, index: next})
        imageAltRef.current[photoIndex.index].value = getValues('images')[next].image_alt;
    }
    const updateImageAltText = (index) => {
        let imageText = imageAltRef.current[index];
        getValues('images')[photoIndex.index].image_alt = imageText.value;
        dispatch(updateProductImageAlt({id: getValues('images')[index].id, image_alt: imageText.value}));
    }
    useEffect(() => {
        let dataImage = [];
        getValues('images').map((item, i) => {
            dataImage.push({
                url: item.image_path,
                type: getFileExtension(item.image_path) == ".mp4" ? "video" : "photo",
                altTag: "some image",
            });
        });
        setViewFile(dataImage);
    }, [getValues('images')])

    return (
        <Fragment>
            <Card>
                <CardBody>
                    <div className="ribbon ribbon-clip ribbon-warning ribbon-left mb-5">Images</div>
                    <div className="form-row mb-3 mt-3">
                        <div className="col-4 offset-8">
                            <Alert color="warning outline">
                                <p>{"Videos can be uploaded after publish"}</p>
                            </Alert>
                        </div>
                    </div>
                    <div className="form-row mb-3">

                        <div ref={dragulaDecoratorRight} style={{display: "contents"}} key={dragulaKey}>
                            {getValues('images').map((item, i) => <Col md={2} index={i} data-id={item.id} key={i}>


                                <Card>
                                    <div className="product-box">
                                        <div className="product-img">
                                            <div className="image-crop-center" style={{height: 200}}>
                                                {getFileExtension(item.image_path) == ".mp4" ?
                                                    <video height="200" width={350} autoPlay={true} muted={true}
                                                           loop={true}>
                                                        <source src={item.image_path} type="video/mp4"/>
                                                    </video>
                                                    : <img className="img-fluid scale-image-6" src={item.image_path}
                                                           alt=""/>}
                                            </div>
                                            <div className="product-hover">
                                                <ul>
                                                    <li>
                                                        <Button color="default" onClick={() => {
                                                            setPhotoIndex({...photoIndex, index: i, isOpen: true})
                                                        }}>
                                                            <i className="icon-eye"></i>
                                                        </Button>
                                                    </li>
                                                    <li>
                                                        <Button color="red" onClick={() => {
                                                            deleteImage(item)
                                                        }}>
                                                            <i className="icon-trash"></i>
                                                        </Button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/*<div className="product-details text-center">*/}
                                        {/*    <h6 className="font-primary" >test</h6>*/}
                                        {/*</div>*/}
                                    </div>
                                </Card>


                            </Col>)}
                        </div>
                        <Col md={2}>
                            <Card>
                                <div className="product-box">
                                    <div className="product-img pt-3 pb-3">
                                        {imagePreloader && <div className="loader-box">
                                            <div className="loader-2"></div>
                                        </div>}
                                        {!imagePreloader && <DropZone
                                            // acceptFiles={getValues('shopify_product_id')?"image/*,.mp4":"image/*"}
                                            extension={getValues('shopify_product_id') ? "image/*,.mp4" : "image/*"}
                                            onChangeFile={onChangeFile}></DropZone>}
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </div>
                </CardBody>
            </Card>
            {photoIndex.isOpen && (
                <div className="lightbox-div">
                    <ReactImageVideoLightbox
                        title={"DAd"}
                        data={viewFile}
                        startIndex={photoIndex.index}
                        showResourceCount={true}
                        onCloseCallback={() => {
                            setPhotoIndex({...photoIndex, isOpen: false})
                        }}
                        onNavigationCallback={(currentIndex) =>
                            console.log(`Current index: ${currentIndex}`)
                        }
                    >
                        <p>test 321</p>
                    </ReactImageVideoLightbox>
                </div>
            )}

        </Fragment>
    )
};

export default ProductImage;

