export const sizeMapper = response => {
    return response.map(res => {
        return {
            id: res.id,
            size: res.id,
            name: res.name,
            ratio:0,
        };
    });
};


export const filerValues =()=>{
    return {
        gridColumnNo: 3,
        pageSize: 12,
        name: "",
        currentPage: 0,
        brand:"",
        status:0,
        sort:"id-desc",
        seller:"",
        trigger:false,
        category:"",
        subCategory:"",
        tags:"",
        stock:"",
        price:"",
        null_check:"",
        season:"",
        country:"",
        commodity:"",
        styles:"",
        compositions:"",
        variant_type:"",
        colour:"",
        offer_type:""
    };
}

export const moreFilterFields=()=>{
    return [
        {name:"Variant Type",key:'variant_type',type:1},
        {name:"Category",key:'category',type:1},
        {name:"Sub Category",key:'subCategory',type:1},
        {name:"Styles",key:'styles',type:2},
        {name:"Tags",key:'tags',type:2},
        {name:"Season",key:'season',type:1},
        {name:"Country",key:'country',type:1},
        {name:"Compositions",key:'compositions',type:2},
        {name:"Commodity",key:'commodity',type:1},
        {name:"Colour",key:'colour',type:1},
        {name:"Stock",key:'stock',type:3},
        {name:"Price",key:'price',type: 3},
        {name:"Empty Field",key:'null_check',type:1},
        {name:"Sale",key:'offer_type',type:1},


    ];
}

export const getFilterValues=(type,value)=>{
    if(type == 1){
        return " is " +value.name;
    }else if(type ==2){
        return  " are "+arrayToStringWithComma(value).name
    }
    else if(type ==3){
        return  " is "+ value.min + " to "+value.max
    }
}
export const arrayToStringWithComma=(value)=>{
    let id = '';
    let name = '';
    // eslint-disable-next-line array-callback-return
    value.map(k => {
        id += `${k.id},`;
        name += `${k.name},`;

    });
    return  {
        id:id.replace(/,\s*$/, ""),
        name:name.replace(/,\s*$/, "")
    };
}

export const buyerFilters =()=>{
    return {
        pageSize: 10,
        name: "",
        currentPage: 1,
        email: "",
        mobile: "",
        country: "",
        type: "",
        customerRepresentative: "",
    }
}
