import {useDispatch, useSelector} from "react-redux";
import React, {Fragment, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import Breadcrumb from "../../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, FormGroup, Nav, Row} from "reactstrap";
import ColoredLine from "../../../../share-components/ui/coloredLine";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import {mapCompositionResponseToTable, mapSearchAutoSuggestion} from "../../../../utils/mapper";
import {
    addProductComposition,
    closeEditDialog,
    deleteProductComposition,
    getAllProductCompositions,
    openEditDialog,
    openNewDialog,
    selectComposition,
    updateProductComposition
} from "../../store/productCompositionSlice";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import ProductCompositionForm from "./productCompositionForm";
import {compositionAutoSuggestionApi} from "../../../../services/manage-product/setting/productCompositionService";
import AsyncSelect from "react-select/async";
import withReducer from "../../../../store/withReducer";
import productReducer from "../../store";
import {Form} from "react-bootstrap";
import PreLoader from "../../../../share-components/ui/preLoader";

const defaultValues = {
    id: '',
    name: '',
    fabric_percentage: '',
    publish_at: '',
    composition_group: ''
};

const dataMenu = [
    {
        type: 0,
        text: 'Products',
        link: '/product'
    },
    {
        type: 1,
        text: 'Product Setting',
        link: ''
    },
    {
        type: 1,
        text: 'Product Composition Group',
        link: ''
    }
];
const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};


const schema = yup.object().shape({
    name: yup.string().required('You must enter a product type name'),
    fabric_percentage: yup.string().required('You must enter a fabric percentage'),
});

const ProductComposition = () => {
    const dispatch = useDispatch();
    const [name, setName] = useState([]);
    const [group, setGroup] = useState([]);
    const [loading, setLoading] = useState(true);
    const composition = useSelector(selectComposition);
    const [buttonDisable, setButtonDisable] = useState(false);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);

    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {errors} = formState;

    const tableColumns = [
        {
            name: 'Composition Name',
            selector: row => row.name,
            sortable: true,
            left: true,
        },
        {
            name: 'Group Name',
            selector: row => row.composition_group_text,
            sortable: true,
            left: true,
        },
        {
            name: 'Fabric Percentage',
            selector: row => row.fabric_percentage,
            sortable: true,
            left: true,
        },
    ];

    useEffect(() => {
        const data = {
            name,
            group
        }
        dispatch(getAllProductCompositions({...data})).then(res=>{
            if (!res.error)
            setLoading(false);
        });
    }, [dispatch, name, group]);

    const onSubmitForm = (dialog, data) => {
        setButtonDisable(true);
        if (dialog.type === 'new') {
            setButtonDisable(false);
            dispatch(addProductComposition({...data})).then(res => {
                if (!res.error) closeDialog(dialog.type)
            });
        } else {
            dispatch(updateProductComposition({...data})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type)
            });
        }
    }

    const closeDialog = () => {
        dispatch(closeEditDialog());
    }

    const onEdit = (row) => {
        console.log(row)
        dispatch(openEditDialog(row));
    }

    const onDelete = (row) => {
        dispatch(deleteProductComposition(row.id))
    }

    const compositionGroupPromiseOption = (inputValue: string) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(compositionAutoSuggestionApi(data)))
            });
        });

    return (
        <Fragment>

            <Container fluid={true} className="product-wrapper">
                {loading ? <><PreLoader></PreLoader></>:<>
                <Breadcrumb data={dataMenu} title="Composition"/>
                <div className="product-grid">
                    <div className="feature-products">
                        <Row>
                            <Col sm="12">
                                {(currentUser.permission).includes('Create Product Composition') ?
                                <Card>
                                    <Row>
                                        <Col sm="6">
                                            <Nav tabs className="border-tab">

                                            </Nav>
                                        </Col>
                                        <Col sm="6">
                                            <div className='float-sm-right p-3'>
                                                <a className="btn btn-sm btn-primary"
                                                   onClick={() => {
                                                       dispatch(openNewDialog())
                                                   }}
                                                > <i className="fa fa-plus"></i>Add Composition</a>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                                    :''}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="3" sm="12">
                                <Form>
                                    <FormGroup>
                                        <input onChange={(e) => setName(e.target.value)} name="name"
                                               type="text" className="form-control border"
                                               placeholder="Search By Composition Name"/>
                                        <i className="fa fa-search"></i>
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col xl="9" sm="12">
                                    <AsyncSelect
                                        className="select2-filter border"
                                        placeholder={<div>Search By Group Name</div>}
                                        isClearable
                                        cacheOptions
                                        defaultOptions
                                        styles={customStyles}
                                        defaultValue={getValues('composition_group')}
                                        loadOptions={compositionGroupPromiseOption}
                                        onChange={(e) => {
                                            setGroup(e?e.value:null)
                                        }}

                                    />
                                    <div
                                        className="invalid-feedback">{errors.composition_group?.message}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardBody>
                        <div className="table-responsive product-table mt-4">
                            <CommonDataTable
                                headerColumns={tableColumns}
                                gridData={mapCompositionResponseToTable(composition)}
                                pagination
                                onEdit={(currentUser.permission).includes('Update Product Composition') ? onEdit:''}
                                onDelete={(currentUser.permission).includes('Delete Product Composition') ? onDelete:''}
                            />
                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div> </>
                }
            </Container>
            <ModalFormSubmit
                defaultValues={defaultValues}
                schema={schema}
                onSubmitForm={onSubmitForm}
                reset={reset}
                formState={formState}
                handleSubmit={handleSubmit}
                title='Composition'
                selector='product'
                dialog={({product}) => product.composition.addDialog}
                closeDialog={closeDialog}
                buttonDisable={buttonDisable}
            >
                <ProductCompositionForm
                    control={control} errors={errors} register={register} setValue={setValue} trigger={trigger}
                    compositionGroupPromiseOption={compositionGroupPromiseOption} getValues={getValues}
                />
            </ModalFormSubmit>
        </Fragment>
    );

};

export default withReducer([{composition: productReducer}])(ProductComposition);