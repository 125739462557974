import React, { useEffect, useRef, useState } from 'react';
import JqxGrid, { IGridProps, jqx } from '../../custom_modules/jqx/jqwidgets-react-tsx/jqxgrid';
import '../../custom_modules/jqx/styles/jqx.base.css';
import '../../custom_modules/jqx/styles/jqx.material.css';


import * as ReactDOMServer from 'react-dom/server';
import _ from '../../@lodash';
import {useSelector} from "react-redux";

const JqxTable = (props) => {
	const { datafields, cellClickDataField=[],cellClickFunction,columns, data, getJqxRef, cellEdit, isDelete,disableCreateRow=false, onDelete=false, ...rest } = props;
	let  myGrid  = null;

	const [user, setUser] = useState(1);
	const themeData = useSelector(({theme}) => theme.theme);
	useEffect(() => {


	}, [themeData.sideMenuToggle]);
	let jqxColumns = [];
	if (isDelete) {

		jqxColumns = [
			...columns.map(item => {
				return {
					...item
				};
			}),
			{
				text: '',
				width: '5%',
				datafield: 'option',
				align: 'center',
				cellsalign: 'center',
				onCellClick(row) {
				},
				cellsrenderer(row, columnfield, value, defaulthtml, columnproperties) {
					if(row !=0) {
						const html = ReactDOMServer.renderToString(
							<div className="jqx-delete-button-div">
								{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
								<a style={{color: 'red'}}>
									<span className='fa fa-trash'></span>
								</a>
							</div>
						);
						return html;
					}
				},

				editable: false
			}
		];
	} else {
		jqxColumns = [
			...columns.map(item => {
				return {
					...item
				};
			})
		];
	}

	const source = {
		datafields,
		datatype: 'array',
		localdata: data
	};
	// eslint-disable-next-line new-cap
	const adapter = new jqx.dataAdapter(source);
	// useEffect(() => {
	// 	setTimeout(
	// 		() =>{
	//
	// 			console.log("Test",myGrid);
	// 			if(myGrid) {
	//
	// 			}
	// 		},
	// 		2000
	// 	);
	// }, []);

	const onCellClick = event => {

		event.preventDefault();
		const { args } = event;
		if (args.datafield === 'option') {
			const rowData=myGrid.getrowdata(args.visibleindex);
			if(onDelete){
				if(rowData.uid != 0){
					myGrid.deleterow(rowData.uid);
					onDelete(args.row.bounddata.id);
				}
			}
		}
		if (cellClickDataField.includes(args.datafield)){
			cellClickFunction(args);
		}
	};

	let numberOfTimePass=0;
	const handlekeyboardnavigation = event => {
		const key = event.charCode ? event.charCode : event.keyCode ? event.keyCode : 0;
		if (key === 13) {
			if(numberOfTimePass ==0) {
				numberOfTimePass=1;
				const compostionElement = document.getElementsByClassName("menu-open");
				if (compostionElement.length < 1) {
					if (!disableCreateRow) {
						myGrid.addrow(0, {});

					}
				}
			}else {
				numberOfTimePass=0;
			}

			return false;
		}
		if (key === 9) {
			const selectedCell = myGrid.getselectedcell();
			if (selectedCell) {
				const currentRow = selectedCell.rowindex;
				const gridInformation = myGrid.getdatainformation();
				const navigationCells = [];
				_.forEach(columns, function(value) {
					if (value.editable === true) {
						navigationCells.push(value.datafield);
					}
				});
				const lastColumn = navigationCells[navigationCells.length - 1];
				// eslint-disable-next-line consistent-return
				_.forEach(navigationCells, function(value, index) {
					if (selectedCell.datafield === value) {
						if (lastColumn === value) {
							myGrid.endcelledit(currentRow, lastColumn);
							if (gridInformation.rowscount === currentRow + 1) {
								if(!disableCreateRow) {
									myGrid.addrow(0, {supplier: null});
								}
							}
							myGrid.selectcell(currentRow + 1, navigationCells[0]);
							myGrid.begincelledit(currentRow + 1, navigationCells[0]);
							return true;
						}
						myGrid.endcelledit(currentRow, navigationCells[index]);
						myGrid.selectcell(currentRow, navigationCells[index + 1]);
						myGrid.begincelledit(currentRow, navigationCells[index + 1]);
						// selectedCell.trigger('click');
						return true;
					}
				});
			}
			return true;
		}
		return false;
	};

	return (
			<JqxGrid
				ref={jqxRef => {
					myGrid = jqxRef;
					getJqxRef(jqxRef);
				}}
				width='100%'
				source={adapter}
				adaptive={false}
				editable
				columns={jqxColumns}
				autoheight
				selectionmode='singlecell'
				onCellclick={onCellClick}
				theme='material'
				handlekeyboardnavigation={handlekeyboardnavigation}
				localization={{ currencysymbol:"£"}}
				{...rest}
			/>
	);
};

export default JqxTable;
