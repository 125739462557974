import React, {Fragment, useState} from 'react';
import {Bell} from "react-feather";
import {CheckAllNotification, Notification} from "../../constant";
import Pusher from "pusher-js";
import {host} from "../../utils/api";
import {useDispatch} from "react-redux";
import {setNotification} from "../../redux/auth/userSlice";
import {notificationMarkAsRead} from "../../services/auth";
import {useHistory} from "react-router-dom";
import "../../components/notification/notofication.scss"

function DesktopNotification(props) {

    const {currentUser} = props;
    const dispatch = useDispatch();
    let history = useHistory();
    const [notificationReset, setNotificationReset] = useState(false)
    const [notificationDropDown, setNotificationDropDown] = useState(false)
    Pusher.logToConsole = true;
    const token = localStorage.getItem('token');
    var pusher = new Pusher('40a16e808dc166ccd733', {
        cluster: 'eu',
        auth: {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        },
        authEndpoint: host + 'broadcasting/auth',
    });
    const domainJost = window.location.hostname == "localhost" ? 'mp.localhost' : `${window.location.hostname}`;
    var channel = pusher.subscribe('private-users-' + currentUser.user.id + '-' + domainJost);
    channel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', function (data) {
        let notifcations = [];
        console.log(data, notifcations)
        notifcations = [
            ...currentUser.user.notifications,
            data
        ];
        console.log(notifcations);
        dispatch(setNotification(notifcations))
    });


    async function markAsRead(id, url) {
        await notificationMarkAsRead(id).then(res => {
            if (!res.errors) {
                let array = currentUser.user.notifications;
                const newArray = array.filter(item => item.id !== id)
                dispatch(setNotification(newArray))
            }
        });
        url ? history.push('/' + url) : history.push('/dashboard')
    }

    function openAllNotifications() {
        history.push('/view-all-notifications')
    }

    console.log(currentUser.user.notifications)

    return (
        <Fragment>
            <div className="notification-box" onClick={() => setNotificationDropDown(!notificationDropDown)}>
                <Bell/><span
                className="badge badge-pill badge-secondary">{(currentUser.user.notifications).length}</span></div>
            <ul className={`notification-dropdown onhover-show-div ${notificationDropDown ? "active" : ""}`}>
                <li>
                    <h6 className="f-18 mb-0">{Notification}</h6>
                </li>
                {currentUser.user.notifications.map((item, index) =>
                    <li className="notification-list" key={index}>

                        <a className="f-12" onClick={(e) => {
                            markAsRead(item.id, '').then()
                        }} href="#"><p><i className="fa fa-circle-o mr-3 font-primary f-12"> </i>{item.message} <span
                            className="pull-right">{item.time}</span></p></a>
                    </li>
                )}

                <li>
                    <button onClick={() => openAllNotifications()}
                            className="btn btn-primary">{CheckAllNotification}</button>
                </li>
            </ul>
        </Fragment>
    );
}

export default DesktopNotification;