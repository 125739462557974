import {Card, CardBody, CardHeader, Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment} from "react";


export const BillingInformation = (props) => {
    const {colSize,orderState}=props;
    return (
        <Fragment>
            <Col sm={colSize}>
                <Card className="ribbon-wrapper ">
                    <CardBody>
                        <div className="ribbon ribbon-clip ribbon-success h6">Billing Information</div>
                        {orderState.data &&
                        <Row>
                            <Col sm="12">
                                <span> <b className="text-secondary">Payment Type:</b> {orderState.data.billing_address?orderState.data.billing_address.company:"-"}</span>
                            </Col>
                            <Col sm="12">
                                <span> <b className="text-secondary">Provider:</b> {orderState.data.billing_address?orderState.data.billing_address.number:"-"}</span>
                            </Col>
                        </Row>}

                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};