import React, {Fragment, useEffect, useRef, useState} from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";

import {useDispatch, useSelector} from "react-redux";
import withReducer from "../../../../store/withReducer";
import {
    getAllPendingReturnRequest,
    getOrderSettings, setOrderData,
    setReturnRequestData,
    setSettings, showPendingReturnRequestList,
    showReturnList, storeReturnRequest
} from "../../store/orderSlice";
import {useHistory, useParams} from "react-router-dom";
import DatePicker from "react-datepicker";
import orderReducer from "../../store";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {jqx} from "./../../../../custom_modules/jqx/jqwidgets-react-tsx/jqxgrid";
import * as ReactDOMServer from "react-dom/server";
import {toast} from "react-toastify";
import {mapOrderReturnResponseToTable} from "../../../../utils/mapper";
import JqxTable from "../../../../share-components/table/JqxTable";
import ButtonLoader from "../../../../share-components/ui/buttonLoader";
import * as SweetAlert from "sweetalert2";
import PreLoader from "../../../../share-components/ui/preLoader";


const EditPendingReturn = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    let history = useHistory();
    const orderState = useSelector(({ order }) => order.order);
    const [loading, isSetLoading] = useState(true);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    let gridRef = useRef(null);

    const dataMenu = [
        {
            type: 1,
            text: 'Pending Return Requests',
            link: '/order/pending-return-requests'
        },
        {
            type: 0,
            text: "Edit Request"
        }
    ];

    useEffect(() => {
        dispatch(getOrderSettings()).then(res => {
            if (!res.error) {
                const results={
                    settings:res.payload,
                    id:null
                }
                dispatch(setSettings(results));
            }
        });
    },[dispatch]);

    useEffect(() => {
        dispatch(showPendingReturnRequestList(id)).then(res => {
            if (!res.error) {
                dispatch(setOrderData(res.payload));
                isSetLoading(false);
            }
        });
    }, [dispatch]);


    const ReasonSource: any = {
        datatype: 'array',
        datafields: [
            { name: 'id', type: 'string' },
            { name: 'name', type: 'string' }
        ],
        localdata: orderState.settings.returnReasons,
    };

    const reasonAdapter: any = new jqx.dataAdapter(ReasonSource, { autoBind: true });

    const dataFields = [
        {name: 'id', type: 'id'},
        {name: 'totalReturn', type: 'number'},
        {name: 'price', type: 'number'},
        {name: 'orderLineItemID', type: 'string'},
        {name: 'lineItemID', type: 'string'},
        {name: 'productName', type: 'string'},
        {name: 'itemSku', type: 'string'},
        {name: 'colour', type: 'string'},
        {name: 'size', type: 'string'},
        {name: 'chooseReturnQuantity', type: 'number'},
        {name: 'returnRequestQuantity', type: 'number'},
        {name: 'orderQuantity', type: 'number'},
        {name: 'reasonRequest', type: 'string'},
        {name: 'status', type: 'string'},
        {name: 'reasonID', type: 'string'},
        {name: 'reason', value: 'reasonID', values: { source: reasonAdapter.records, value: 'id', name: 'name' } },
        {name: 'isRestock', type: 'bool' },

    ];

    // eslint-disable-next-line no-shadow
    const getJqxRef = jqx => {
        if (jqx) {
            gridRef = jqx;
        }
    };

    const columns = [
        { datafield: 'id', hidden: true },
        { datafield: 'totalReturn', hidden: true },
        { datafield: 'price', hidden: true },
        { datafield: 'orderLineItemID', hidden: true },
        { datafield: 'lineItemID', hidden: true },
        {
            text: 'Product Name',
            datafield: 'productName',
            width: '18.2%',
            editable: false,
            align: 'center',
            cellsalign: 'left',
            cellbeginedit(row, datafield, columntype) {},
        },
        {
            text: 'Item SkU',
            datafield: 'itemSku',
            width: '5%',
            editable: false,
            align: 'center',
            cellsalign: 'left',
            cellbeginedit(row, datafield, columntype) {},
        },
        {
            text: 'Colour',
            datafield: 'colour',
            width: '5%',
            editable: false,
            align: 'center',
            cellsalign: 'left',
            cellbeginedit(row, datafield, columntype) {},
        },
        {
            text: 'Size',
            datafield: 'size',
            width: '5%',
            editable: false,
            align: 'center',
            cellsalign: 'left',
            cellbeginedit(row, datafield, columntype) {},
        },
        {
            text: 'Choose Return Quantity',
            datafield: 'chooseReturnQuantity',
            width: '10%',
            editable: true,
            align: 'center',
            cellsalign: 'right',
            cellbeginedit(row, datafield, columntype, val) {
                let found=0;
                gridRef.getselectedrowindexes().forEach(element => element===row?found++:"");
                return (found>0);
            },
            cellendedit(row, datafield, columntype, oldvalue,value) {
                gridRef.setcellvalue(row,'totalReturn',(value*gridRef.getcellvalue(row,'price')));
            },
            validation(cell, value) {
                if(value>gridRef.getcellvalue(cell.row,'returnRequestQuantity')){
                    // toast.error(`Return quantity cannot exceed the ordered quantity - (${gridRef.getcellvalue(cell.row,'returnRequestQuantity')})`, {
                    //     position: toast.POSITION.TOP_RIGHT,
                    // });
                    return false;
                }
                if(value<=0){
                    // toast.error("Invalid quantity value", {
                    //     position: toast.POSITION.TOP_RIGHT,
                    // });
                    return false;
                }
                return true;
            }
        },
        {
            text: 'Return Request Quantity',
            datafield: 'returnRequestQuantity',
            width: '10%',
            editable: false,
            align: 'center',
            cellsalign: 'right',
            cellbeginedit(row, datafield, columntype) {},
        },
        {
            text: 'Ordered Quantity',
            datafield: 'orderQuantity',
            width: '5%',
            editable: false,
            align: 'center',
            cellsalign: 'right',
            cellbeginedit(row, datafield, columntype) {},
        },
        {
            text: 'Request Reason',
            datafield: 'reasonRequest',
            width: '15%',
            editable: false,
            align: 'center',
            cellsalign: 'left',
            cellbeginedit(row, datafield, columntype) {},
        },
        {
            text: 'Status',
            datafield: 'status',
            width: '5%',
            editable: false,
            align: 'center',
            cellsalign: 'left',
            cellbeginedit(row, datafield, columntype) {},
        },
        {
            text: 'Is Restock',
            datafield: 'isRestock',
            editable: true,
            width: '5%',
            align: 'center',
            cellsalign: 'right',
            columntype: 'checkbox',
            cellbeginedit(row, datafield, columntype, val) {
                let found=0;
                gridRef.getselectedrowindexes().forEach(element => element===row?found++:"");
                return (found>0);
            }
        },
        {
            text: 'Reason',
            datafield: 'reasonID',
            displayfield: 'reason',
            width: '15%',
            align: 'center',
            columntype: 'dropdownlist',
            editable: true,
            cellbeginedit(row, datafield, columntype, val) {
                let found=0;
                gridRef.getselectedrowindexes().forEach(element => element===row?found++:"");
                return (found>0);
            },
            createeditor: (row: number, value: any, editor: any): void => {
                editor.jqxDropDownList({ width: '99%', height: 50, source: reasonAdapter, displayMember: 'name', valueMember: 'id' });
            },
        },
    ];

    const onSelectRow = event => {
        if(event.args.rowindex && (event.args.rowindex).length>0){
            gridRef.setcellvalue(event.args.rowindex, 'chooseReturnQuantity', '');
        }else{
            const gridInformation = gridRef.getdatainformation();
            for (let i = 0; i < gridInformation.rowscount; i++) {
                gridRef.setcellvalue(i, 'reason', '');
                gridRef.setcellvalue(i, 'reason_id', null);
                gridRef.setcellvalue(i, 'chooseReturnQuantity', '');
            }
        }
    };

    const onRowunselect = event => {
        gridRef.setcellvalue(event.args.rowindex, 'chooseReturnQuantity', '');
        gridRef.setcellvalue(event.args.rowindex, 'reason', '');
        gridRef.setcellvalue(event.args.rowindex, 'reason_id', null);
    };

    const submitData = () =>{
        let foundErrors=0;
        let accept=[];
        let decline=[];
        let notIncluded=false;
        const gridData=gridRef.getdatainformation();
        for(let i=0;i<gridData.rowscount;i++){
            notIncluded=false;
            gridRef.getselectedrowindexes().forEach(element =>{
                if(element===i){
                    notIncluded=true;
                    if(gridRef.getcellvalue(i,'chooseReturnQuantity')===undefined || gridRef.getcellvalue(i,'chooseReturnQuantity')==="" || gridRef.getcellvalue(i,'reasonID')===undefined || gridRef.getcellvalue(i,'reasonID')===null){
                        foundErrors++;
                    }
                    accept.push({
                        id:gridRef.getcellvalue(i,'id'),
                        order_line_item_id:gridRef.getcellvalue(i,'orderLineItemID'),
                        line_item_id:gridRef.getcellvalue(i,'lineItemID'),
                        reason:gridRef.getcellvalue(i,'reasonID'),
                        total:gridRef.getcellvalue(i,'totalReturn'),
                        restock:gridRef.getcellvalue(i,'isRestock')?gridRef.getcellvalue(i,'isRestock'):false,
                        quantity:gridRef.getcellvalue(i,'chooseReturnQuantity'),
                    });
                }
            });
            if(!notIncluded)
                decline.push(gridRef.getcellvalue(i,'id'));
        }
        const dataSet={
            orderID:orderState.data.id,
            orderNo:orderState.data.order_no,
            id:id,
            accept:accept,
            decline:decline
        };

        if(foundErrors>0){
            toast.error("Please fill all required fields", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }else{
            SweetAlert.fire({
                title: 'Are you sure want to process this return request?',
                text: "Once confirmed, the return request will be sent to the system",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, Proceed',
                cancelButtonText: 'No, cancel',
                reverseButtons: true
            })
                .then((result) => {
                    setButtonDisabled(true);
                    dispatch(storeReturnRequest(dataSet)).then(res => {
                        if (!res.error) {
                            setButtonDisabled(false);
                            history.push('/order/pending-return-requests');
                        }
                    });
                })
        }
    };

    return (
        <Fragment>
                {loading ? <>
                        <PreLoader></PreLoader>
                    </>
                    : <>
            <Breadcrumb title="Edit Pending Return Requests" data={dataMenu}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <h5 className="mb-2">Items from Order {orderState.data?orderState.data.order_name:""}</h5>

                                {orderState.settings.returnReasons.length>0 && <JqxTable
                                    datafields={dataFields}
                                    columns={columns}
                                    data={orderState.list ? orderState.list : null}
                                    myGrid={gridRef}
                                    isDelete={false}
                                    disableCreateRow
                                    getJqxRef={getJqxRef}
                                    onRowselect={onSelectRow}
                                    onRowunselect={onRowunselect}
                                    selectionmode={'checkbox'}
                                />}
                                <div className="mt-2 d-flex justify-content-end">
                                    <div className="p-2">
                                        <ButtonLoader disabled={buttonDisabled} onClick={submitData} btntext="Save" className="btn btn-primary btn-block"/>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
                    </>}
        </Fragment>
    );
}

export default withReducer([{order: orderReducer}])(EditPendingReturn);