import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, FormGroup, Input, Label, Nav, Row} from "reactstrap";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import {mapOrderResponseToTable, mapSellerRoleResponseToTable} from "../../../utils/mapper";
import {useDispatch, useSelector} from "react-redux";
import withReducer from "../../../store/withReducer";
// import useNavigate from 'react-router-dom';

import {
    downloadBulkOrderPdf,
    downloadOrderPdf,
    getOrders,
    getSellers,
    getSellerSettings,
    selectOrder,
    getOrderSettings,
    setSettings
} from "../store/orderSlice";
import {useHistory} from "react-router-dom";
import DataTable from "react-data-table-component";
import Select from "react-select";
import orderReducer from "../store";
import {CheckboxSelection} from "./table/checkboxSelection";
import {openNewDialog} from "../../manage-product/store/prodcutSlice";
import {Badge} from "react-bootstrap";
import PreLoader from "../../../share-components/ui/preLoader";
import {toast} from "react-toastify";


const Order = (props) => {
    const OrderFormat = row => <a>{row.orderName}</a>;
    const dispatch = useDispatch();
    let history = useHistory();
    const [page, setPage] = useState(1);
    const [selectedRow, setSelectedRow] = useState(false);
    const [loading, isSetLoading] = useState(true);
    const [checkBoxData, setCheckBox] = useState({packingSlip: [], pickingTicket: []});
    const [orderData, setOrderData] = useState([]);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);
    const [status, setStatus] = useState(null);
    const [financialStatus, setFinancialStatus] = useState(null);
    const [paymentType, setPaymentType] = useState(null);
    const orderState = useSelector(({order}) => order.order);
    const orderReduxData = useSelector(selectOrder);
    const [country, setCountry] = useState(null);
    useEffect(() => {
        setOrderData(mapOrderResponseToTable(orderReduxData, checkBoxData));
    }, [orderReduxData]);
    const dataMenu = [
        {
            type: 0,
            text: 'Orders',
        }
    ];
    const conditionalRowStyles = [

        {
            when: row => row.isPackingSlip,
            style: row => ({
                backgroundColor: '#FFD0D0',
            }),
        },
        {
            when: row => row.isPackingTicket,
            style: row => ({
                backgroundColor: '#AAB7F9',
            }),
        },
        {
            when: row => (row.isPackingTicket && row.isPackingSlip),
            style: row => ({
                backgroundColor: '#BEF781'
            }),


        },

    ];

    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 50,
            border: 'none',
        })
    };

    const fulfillmentOptions = [
        {value: 'unfulfilled ', label: 'Unfulfilled'},
        {value: 'fulfilled', label: 'Fulfilled'},
        {value: 'partial', label: 'Partial'},
    ]

    const paymentStatusOptions = [
        {value: 'pending', label: 'Pending'},
        {value: 'paid', label: 'Paid'},
        {value: 'voided', label: 'Voided'},
        {value: 'partially_refunded', label: 'Partially refunded'},
        {value: 'refunded', label: 'Refunded'},
    ]

    const paymentTypeOptions = [
        {value: 'shopify_payments', label: 'Shopify Payments'},
        {value: 'paypal', label: 'Paypal'},
        // {value: 'others', label: 'Others'},
    ]

    const checkBoxChange = (row, obj, isChecked) => {
        let checkArray = checkBoxData[obj];
        if (isChecked) {
            checkArray.push(row.id);
        } else {
            if (checkArray.includes(row.id)) {
                let packIndex = checkArray.indexOf(row.id);
                checkArray.splice(packIndex, 1);
            }
        }

        // console.log(pack);
        setCheckBox(prevState => ({
            ...prevState,
            obj: checkArray
        }));

    }
    const allCheckBoxChange = (obj, isChecked) => {
        const data = orderData;
        let checkArray = checkBoxData[obj];
        if (isChecked) {
            data.map((item, index) => {
                if (!checkArray.includes(item.id)) {
                    item.customer && checkArray.push(item.id);
                }
            });
        } else {
            data.map((item, index) => {
                if (checkArray.includes(item.id)) {
                    let packIndex = checkArray.indexOf(item.id);
                    checkArray.splice(packIndex, 1);
                }
            });

        }

        // console.log(pack);
        setCheckBox(prevState => ({
            ...prevState,
            obj: checkArray
        }));


    }
    useEffect(() => {
        
        setOrderData(mapOrderResponseToTable(orderReduxData, checkBoxData));
        dispatch(getOrderSettings()).then(res=> {
            dispatch(setSettings(res.payload))
        });
    }, [checkBoxData,dispatch])

    let tableColumns = [
        {
            name: 'Order ID',
            selector: row => row.orderName,
            sortable: true,
            cell: (row) => <a className="text-primary" style={{cursor: "pointer"}} onClick={onView}
                              id={row.id}>{row.orderName}</a>,
            center: false,
        },
        {
            name: 'Date',
            selector: row => row.orderDate,
            sortable: true,
            center: false,
        },
        {
            name: 'Payment Status',
            selector: row => <p className="text-capitalize">{row.paymentStatus}</p> ,
            sortable: true,
            center: false,
        },
        {
            name: 'Total (£)',
            selector: row => row.total,
            sortable: true,
            center: false,
        },
        {
            name: 'Payment Method',
            selector: row => row.payment,
            sortable: true,
            center: false,
        },
        {
            name: 'Fulfillment  Status',
            selector: row => row.fulfillmentStatus,
            sortable: true,
            center: false,
        },
        {
            name: 'Country',
            selector: row => row.country,
            sortable: true,
            center: false,
        },
    ];

    if((currentUser.permission).includes('Create Packing Slips')){
        tableColumns=[
            ...tableColumns,
            {
                name: 'Packing Slip',
                selector: row => row.payment,
                sortable: false,
                center: true,
                cell: (row) => <input disabled={!row.customer} onChange={(e) => {

                    checkBoxChange(row, 'packingSlip', e.target.checked);


                }} checked={row.isPackingSlip} type={"checkbox"}/>
            }
        ];
    }

    if((currentUser.permission).includes('Create Picking Ticket')){
        tableColumns=[
            ...tableColumns,
            {
                name: 'Picking Ticket',
                selector: row => row.id,
                sortable: false,
                center: true,
                cell: (row) => <input disabled={!row.customer} onChange={(e) => {
                    checkBoxChange(row, 'pickingTicket', e.target.checked);

                }} checked={row.isPackingTicket} type={"checkbox"}/>
            }

        ];
    }

    tableColumns=[
        ...tableColumns,
        {
            name: 'Action',
            selector: (row) => <><a onClick={() => onTrackingView(row)} className={`btn btn-sm p-2 ${!row.customer?'disabled':''}`} style={{backgroundColor:`${row.btnColor}`, color:'white'}} title={row.fulfillmentStatus.toUpperCase()}> <i className="fa fa-truck"/>
            </a>
                {!row.isFullFilled && <a onClick={() => onReturn(row)} className={`ml-1 btn btn-sm btn-warning p-2 ${!row.customer?'disabled':''}`}> <i className="fa fa-mail-reply"/></a>}</>,
            sortable: true,
            center: false,
        }
    ];

    useEffect(() => {
        getData();
    }, [page, status, financialStatus, paymentType, country]);

    const getData = () => {
        const data = {
            page: page,
            pageSize: 10,
            status:status,
            financialStatus:financialStatus,
            paymentType:paymentType,
            country:country
        }

        dispatch(getOrders(data)).then(res => {
            if (!res.error) {
                isSetLoading(false);
            }
        });

    }

    // console.log(orderState.countries);
    const onEdit = (row) => {
        // history.push('seller/'+row.id+'/edit');
    }

    const generatePackingSlip = () => {
        processPDFDownload(checkBoxData.packingSlip,"Package Slip");
    }
    const generatePickingTicket = () => {
        processPDFDownload(checkBoxData.pickingTicket,"Picking Ticket");
    }

    const onView = (state) => {
        history.push('/order/' + state.target.id + '/view');
    }
    const onReturn = (row) => {
        if(row.customer){
            history.push('/order/' + row.id + '/return-items');
        }else{
            toast.error("No customer found for the selected order", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }
    const onTrackingView = (row) => {
        if(row.customer){
            history.push('/order/' + row.id + '/tracking/add');
        }else{
            toast.error("No customer found for the selected order", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }

    }
    const processPDFDownload =(ids,name)=>{
        const data={
            ids:ids,
            name:name
        }
        dispatch(downloadBulkOrderPdf(data)).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `bulk_${name.replace(/\s+/g, '-')}_${(new Date().toJSON().slice(0,10))}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    }

    return (
        <Fragment>
            {loading ? <>
                    <PreLoader></PreLoader>
                </>
                : <>
            <Breadcrumb title="Orders" data={dataMenu}/>
            <Container fluid={true}>
                {((currentUser.permission).includes('Create Packing Slips') && (currentUser.permission).includes('Create Picking Ticket')) &&
                <Row>
                    <Col sm="12">
                        <Card>
                            <Row>
                                <Col sm="6">
                                    <div className="p-4">
                                        <div  style={{display:"flex"}}>
                                            {((currentUser.permission).includes('Create Packing Slips') && (currentUser.permission).includes('Create Picking Ticket')) &&
                                            <div className=" mr-2">
                                                <Badge className="mr-2 badge-green" pill>{' '}</Badge>
                                                <span>Packing & Picking ({(checkBoxData.packingSlip.filter(element => checkBoxData.pickingTicket.includes(element))).length})</span>
                                            </div>}
                                            {(currentUser.permission).includes('Create Packing Slips') &&
                                            <div className=" mr-2">
                                                <Badge style={{backgroundColor:"#efed88 !important"}} className="mr-2 badge-red" pill>{' '}</Badge>
                                                <span>Packing Slip ({checkBoxData.packingSlip.length})</span>
                                            </div>}
                                            {(currentUser.permission).includes('Create Picking Ticket') &&
                                            <div className=" mr-2">
                                                <Badge className="mr-2 badge-blue" pill>{' '}</Badge>
                                                <span>Picking Ticket ({checkBoxData.pickingTicket.length})</span>
                                            </div>}
                                        </div>


                                    </div>

                                </Col>

                                <Col sm="6">
                                    <div className='float-sm-right p-3'>
                                        {(checkBoxData.packingSlip.length > 0 && (currentUser.permission).includes('Create Packing Slips')) &&
                                        <a onClick={generatePackingSlip} className="btn btn-sm btn-primary mr-2"> <i
                                            className="fa fa-plus"></i> Packing Slip</a>}
                                        {(checkBoxData.pickingTicket.length > 0 && (currentUser.permission).includes('Create Picking Ticket')) &&
                                        <a onClick={generatePickingTicket} className="btn btn-sm btn-success"> <i
                                            className="fa fa-plus"></i> Picking Ticket</a>}
                                    </div>
                                </Col>
                            </Row>

                        </Card>
                    </Col>
                </Row>}
                <div className="product-grid">
                    <div className="feature-products">
                        <Row>
                            <Col sm="4">
                                <FormGroup>
                                    <Select
                                        placeholder={<div>Fulfillment status</div>}
                                        className="select2-filter border"
                                        isClearable
                                        cacheOptions
                                        styles={customStyles}
                                        options={fulfillmentOptions}
                                        onChange={(e) => {
                                            // console.log(e)
                                            setStatus(e?e.value:null)
                                        }}

                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="4">
                                <FormGroup>
                                    <Select
                                        placeholder={<div>Payment status</div>}
                                        className="select2-filter border"
                                        isClearable
                                        cacheOptions
                                        styles={customStyles}
                                        options={paymentStatusOptions}
                                        onChange={(e) => {
                                            // console.log(e)
                                            setFinancialStatus(e?e.value:null)
                                        }}

                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="4">
                                <FormGroup>
                                    <Select
                                        placeholder={<div>Payment type</div>}
                                        className="select2-filter border"
                                        isClearable
                                        cacheOptions
                                        styles={customStyles}
                                        options={paymentTypeOptions}
                                        onChange={(e) => {
                                            // console.log(e)
                                            setPaymentType(e?e.value:null)
                                        }}

                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="4">
                                <FormGroup>
                                    <Select
                                        placeholder={<div>Country</div>}
                                        className="select2-filter border"
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        isClearable
                                        styles={customStyles}
                                        options={orderState.settings.countries}
                                        onChange={(e) => {
                                            console.log(e)
                                            setCountry(e?e.name:null)
                                        }}

                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                {orderData.length>0 && <Row className="mb-2">
                                    <Col md="12">
                                        <div className='float-sm-right' style={{display: 'flex'}}>
                                            {(currentUser.permission).includes('Create Packing Slips') &&
                                            <div className="checkbox checkbox-dark mr-5">
                                                <Input id="packing_slip_check_box" type="checkbox" onChange={(e) => {
                                                    allCheckBoxChange('packingSlip', e.target.checked);

                                                }}/>
                                                <Label for="packing_slip_check_box">Packing Slip</Label>
                                            </div>}

                                            {(currentUser.permission).includes('Create Picking Ticket') &&
                                            <div className="checkbox checkbox-dark">
                                                <Input id="picking_ticket_check_box" type="checkbox" onChange={(e) => {
                                                    allCheckBoxChange('pickingTicket', e.target.checked);
                                                }}/>
                                                <Label for="picking_ticket_check_box">Picking Ticket</Label>
                                            </div>}
                                        </div>
                                    </Col>


                                </Row>}
                                <Row>
                                    <div className="table-responsive product-table">
                                        <CommonDataTable
                                            headerColumns={tableColumns}
                                            gridData={orderData}
                                            onEdit={onEdit}
                                            onView={onView}
                                            paginationServer
                                            paginationTotalRows={orderState.orderTableRow}
                                            paginationPerPage={10}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true
                                            }}
                                            conditionalRowStyles={conditionalRowStyles}
                                            onChangePage={page => setPage(page)}
                                            noAction={true}
                                        />

                                    </div>
                                </Row>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
                </>}
        </Fragment>
    );
}

export default withReducer([{order: orderReducer}])(Order);