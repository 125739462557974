import {Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment} from "react";
import Select from "react-select";
import Switch from "react-switch";

export const ExtraInformation = (props) => {
    const {errors,register,trigger,setValue,getValues,isTaxChargeable,setIsTaxChargeable}=props;
    const handleIsTaxChargeableChange = (checked) => {
        setIsTaxChargeable(checked);
        setValue('chargeTax',checked)
    }
    return (
        <Fragment>
            <Row>
                <Col md={4}>
                    <FormGroup>
                        <Label className="form-label">Minimum Order<span style={{color:"red"}}>*</span></Label>
                        <input name="minOrder" type="number" className={`form-control ${errors.minOrder ? 'is-invalid' : ''}`} placeholder="10" {...register('minOrder')}  />
                        <div className="invalid-feedback">{errors.minOrder?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label className="form-label">Delivery Info</Label>
                        <input name="deliveryInfo" type="text" className={`form-control ${errors.deliveryInfo ? 'is-invalid' : ''}`} placeholder="" {...register('deliveryInfo')}  />
                        <div className="invalid-feedback">{errors.deliveryInfo?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label className="form-label">Charge Taxes On Products<span style={{color:"red"}}>*</span></Label>
                        <div className="form-control pl-0 border-0">
                            <Switch uncheckedIcon={false} checkedIcon={false} onChange={handleIsTaxChargeableChange} checked={isTaxChargeable} className="react-switch" />
                        </div>
                        <div style={{ width:'100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545'}}>{errors.isPremium?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">Social Facebook Url</Label>
                        <input name="socialFacebook" type="text" className={`form-control ${errors.socialFacebook ? 'is-invalid' : ''}`} placeholder="facebook.com/pg/tradegala.ltd/posts/" {...register('socialFacebook')}  />
                        <div className="invalid-feedback">{errors.socialFacebook?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">Social Instagram Url</Label>
                        <input name="socialInstagram" type="text" className={`form-control ${errors.socialInstagram ? 'is-invalid' : ''}`} placeholder="instagram.com/tradegala_marketplace" {...register('socialInstagram')}  />
                        <div className="invalid-feedback">{errors.socialInstagram?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">Social Twitter Url</Label>
                        <input name="socialTwitter" type="text" className={`form-control ${errors.socialTwitter ? 'is-invalid' : ''}`} placeholder="twitter.com/tradegala" {...register('socialTwitter')}  />
                        <div className="invalid-feedback">{errors.socialTwitter?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">Snapppt Instagram Widget Code</Label>
                        <input name="instagramWidgetCode" type="text" className={`form-control ${errors.instagramWidgetCode ? 'is-invalid' : ''}`} placeholder="" {...register('instagramWidgetCode')}  />
                        <div className="invalid-feedback">{errors.instagramWidgetCode?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <Label className="form-label">Shipping Option<span style={{color:"red"}}>*</span></Label>
                    <FormGroup className="m-t-15 m-checkbox-inline mb-0 ml-2">
                        <div className="radio radio-primary">
                            <input id="radioinline3" type="radio" name="shippingOption" value="1" {...register('shippingOption')} />
                            <Label className="mb-0" for="radioinline3">Shopify Default Shipping</Label>
                        </div>
                        <div className="radio radio-primary">
                            <input id="radioinline4" type="radio" name="shippingOption" value="2" {...register('shippingOption')}  />
                            <Label className="mb-0" for="radioinline4">Country Wise Shipping</Label>
                        </div>
                        <div style={{ width:'100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545'}}>{errors.shippingOption?.message}</div>
                    </FormGroup>
                </Col>
            </Row>
        </Fragment>
    );
};