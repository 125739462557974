import React, {useEffect, useState} from 'react';
import {Col, Input, Nav, NavItem, NavLink, Row} from "reactstrap";
import Select from "react-select";
import {getSubCategories, setProductFilter, setProductSubCategory} from "../../store/prodcutSlice";
import {useDispatch} from "react-redux";
import InputRange from 'react-input-range';
import CustomInputRange from "../../../../share-components/ui/customInputRange";
import ScrollArea from 'react-scrollbar';
import {CheckCircle, Delete, Info, MoreHorizontal, RefreshCcw, Target} from "react-feather";


function ProductFilter(props) {
    const dispatch = useDispatch();
    const {productState, customStyles} = props;
    const productSettings = productState.productIndexSettings;




    document.addEventListener("mousedown", (event) => {
        const concernedElement = document.querySelector(".customizer-contain");
            if(concernedElement) {
                if (!concernedElement.contains(event.target)) {
                    document.querySelector(".customizer-contain").classList.remove('open');
                }
            }

    });


    return (
        <>
            <div style={{height: '1000'}}>
                <ScrollArea
                    horizontal={false}
                    vertical={true}
                >
                     <Row>
                         <Col md="12">
                             <Nav tabs className="border-tab">
                                 <NavItem><NavLink
                                     className={productState.filter.variant_type==="" ? "active" : ""}
                                     onClick={() => {
                                         dispatch(setProductFilter({
                                             ...productState.filter,        // dispatch(setProductFilter({
                                             variant_type: "",
                                         }));
                                     }}> ALL</NavLink></NavItem>
                                 <NavItem><NavLink
                                     className={productState.filter.variant_type?.id === 1 ? "active" : ""}
                                     onClick={() => {
                                         dispatch(setProductFilter({
                                             ...productState.filter,        // dispatch(setProductFilter({
                                             variant_type: {id:1,name:"Pack"}
                                         }));
                                     }}> Pack </NavLink></NavItem>
                                 <NavItem><NavLink
                                     className={productState.filter.variant_type?.id === 2 ? "active" : ""}
                                     onClick={() => {
                                         dispatch(setProductFilter({
                                             ...productState.filter,        // dispatch(setProductFilter({
                                             variant_type: {id:2,name:"Single"}
                                         }));
                                     }}> Single </NavLink></NavItem>
                             </Nav>
                         </Col>
                     </Row>
                    <Row  style={{marginTop:-10}}>
                        <Col md="12">
                            {/*<div className="product-filter pb-0">*/}
                            {/*    <h6 className="f-w-600">Category*/}
                            {/*    </h6>*/}
                            {/*</div>*/}
                            <Select getOptionLabel={(option) => option.name}
                                    className="select2-filter"
                                    menuPortalTarget={document.body}
                                    styles={{menuPortal: base => ({...base, zIndex: 100})}}
                                    value={productState.filter.category}
                                    getOptionValue={(option) => option.id}
                                    onChange={(e) => {
                                        dispatch(setProductFilter({
                                            ...productState.filter,
                                            category: e,
                                            subCategory: "",
                                            styles: ""
                                        }));
                                    }}
                                    isClearable={true}
                                    placeholder="select Categories"
                                // styles={customStyles}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    options={productSettings.categories.filter(function (value, index) {
                                        return value.parent_id == 0
                                    })}/>
                        </Col>
                    </Row>
                    {productState.filter.category && <Row className="mt-3">
                        <Col md="12">
                            {/*<div className="product-filter pb-0">*/}
                            {/*    <h6 className="f-w-600">Sub Category*/}
                            {/*    </h6>*/}
                            {/*</div>*/}
                            <Select getOptionLabel={(option) => option.name}
                                    className="select2-filter"
                                    menuPortalTarget={document.body}
                                    styles={{menuPortal: base => ({...base, zIndex: 100})}}
                                    value={productState.filter.subCategory}
                                    getOptionValue={(option) => option.id}
                                    onChange={(e) => {
                                        dispatch(setProductFilter({
                                            ...productState.filter,
                                            subCategory: e,
                                            styles: ""
                                        }));
                                    }}
                                    isClearable={true}
                                    placeholder="select Sub Categories"
                                // styles={customStyles}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    options={productSettings.categories.filter(function (value, index) {
                                        return value.parent_id == productState.filter.category?.id
                                    })}/>
                        </Col>
                    </Row>}
                    {productState.filter.subCategory && <Row className="mt-3">
                        <Col md="12">
                            {/*<div className="product-filter pb-0">*/}
                            {/*    <h6 className="f-w-600">Tag*/}
                            {/*    </h6>*/}
                            {/*</div>*/}
                            <Select getOptionLabel={(option) => option.name}
                                    isMulti={true}
                                    className="select2-filter"
                                    menuPortalTarget={document.body}
                                    styles={{menuPortal: base => ({...base, zIndex: 100})}}
                                    value={productState.filter.styles}
                                    getOptionValue={(option) => option.id}
                                    isOptionDisabled={() => productState.filter.styles && productState.filter.styles.length > 4}
                                    onChange={(e) => {
                                        dispatch(setProductFilter({
                                            ...productState.filter,
                                            styles: e.length > 0 ? e : "",
                                        }));
                                    }}
                                    placeholder="select styles"
                                // styles={customStyles}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    options={productSettings.styles.filter(function (value, index) {
                                        return (value.category).filter(function (v, i) {
                                            return v.id == productState.filter.subCategory?.id
                                        }).length > 0
                                    })}/>
                        </Col>
                    </Row>}
                    <Row className="mt-3">
                        <Col md="12">
                            {/*<div className="product-filter pb-0">*/}
                            {/*    <h6 className="f-w-600">Tag*/}
                            {/*    </h6>*/}
                            {/*</div>*/}
                            <Select getOptionLabel={(option) => option.name}
                                    isMulti={true}
                                    className="select2-filter"
                                    menuPortalTarget={document.body}
                                    styles={{menuPortal: base => ({...base, zIndex: 100})}}
                                    value={productState.filter.tags}
                                    getOptionValue={(option) => option.id}
                                    onChange={(e) => {
                                        console.log(e)
                                        dispatch(setProductFilter({
                                            ...productState.filter,
                                            tags: e.length > 0 ? e : "",
                                        }));
                                    }}
                                    isOptionDisabled={() => productState.filter.tags && productState.filter.tags.length > 4}
                                    placeholder="select tags"
                                // styles={customStyles}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    options={productSettings.tags}/>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md="12">
                            {/*<div className="product-filter pb-0">*/}
                            {/*    <h6 className="f-w-600">Tag*/}
                            {/*    </h6>*/}
                            {/*</div>*/}
                            <Select getOptionLabel={(option) => option.name}
                                    className="select2-filter"
                                    menuPortalTarget={document.body}
                                    styles={{menuPortal: base => ({...base, zIndex: 100})}}
                                    value={productState.filter.season}
                                    isClearable={true}
                                    getOptionValue={(option) => option.id}
                                    onChange={(e) => {
                                        dispatch(setProductFilter({
                                            ...productState.filter,
                                            season: e,
                                        }));
                                    }}
                                    placeholder="select season"
                                // styles={customStyles}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    options={productSettings.seasons}/>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md="12">
                            {/*<div className="product-filter pb-0">*/}
                            {/*    <h6 className="f-w-600">Tag*/}
                            {/*    </h6>*/}
                            {/*</div>*/}
                            <Select getOptionLabel={(option) => option.name}
                                    className="select2-filter"
                                    menuPortalTarget={document.body}
                                    styles={{menuPortal: base => ({...base, zIndex: 100})}}
                                    value={productState.filter.country}
                                    getOptionValue={(option) => option.id}
                                    onChange={(e) => {
                                        dispatch(setProductFilter({
                                            ...productState.filter,
                                            country: e,
                                        }));
                                    }}
                                    isClearable={true}
                                    placeholder="select country"
                                // styles={customStyles}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    options={productSettings.countries}/>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col md="12">
                            {/*<div className="product-filter pb-0">*/}
                            {/*    <h6 className="f-w-600">Tag*/}
                            {/*    </h6>*/}
                            {/*</div>*/}
                            <Select getOptionLabel={(option) => option.name}
                                    isMulti={true}
                                    className="select2-filter"
                                    menuPortalTarget={document.body}
                                    styles={{menuPortal: base => ({...base, zIndex: 100})}}
                                    value={productState.filter.compositions}
                                    getOptionValue={(option) => option.id}
                                    onChange={(e) => {
                                        dispatch(setProductFilter({
                                            ...productState.filter,
                                            compositions: e.length > 0 ? e : "",
                                        }));
                                    }}
                                    isOptionDisabled={() => productState.filter.compositions && productState.filter.compositions.length > 4}
                                    placeholder="select compositions"
                                // styles={customStyles}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    options={productSettings.compositions}/>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col md="12">
                            {/*<div className="product-filter pb-0">*/}
                            {/*    <h6 className="f-w-600">Tag*/}
                            {/*    </h6>*/}
                            {/*</div>*/}
                            <Select getOptionLabel={(option) => option.name}
                                    className="select2-filter"
                                    menuPortalTarget={document.body}
                                    styles={{menuPortal: base => ({...base, zIndex: 100})}}
                                    value={productState.filter.commodity}
                                    getOptionValue={(option) => option.id}
                                    onChange={(e) => {
                                        dispatch(setProductFilter({
                                            ...productState.filter,
                                            commodity: e,
                                        }));
                                    }}
                                    placeholder="select commodity"
                                    isClearable={true}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    options={productSettings.commodities}/>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md="12">
                            {/*<div className="product-filter pb-0">*/}
                            {/*    <h6 className="f-w-600">Tag*/}
                            {/*    </h6>*/}
                            {/*</div>*/}
                            <Select getOptionLabel={(option) => option.name}
                                    className="select2-filter"
                                    menuPortalTarget={document.body}
                                    styles={{menuPortal: base => ({...base, zIndex: 100})}}
                                    value={productState.filter.colour}
                                    getOptionValue={(option) => option.id}
                                    onChange={(e) => {
                                        dispatch(setProductFilter({
                                            ...productState.filter,
                                            colour: e,
                                        }));
                                    }}
                                    placeholder="select Colour"
                                    isClearable={true}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    options={productSettings.colours}/>
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col md="12">
                            <div className="product-filter pb-0">

                                <div style={{marginTop:-15}}>
                                    <CustomInputRange
                                        title="Stock"
                                        maxRange={2000}
                                        stateValue={productState.filter.stock}
                                        clearRange={() => {
                                            dispatch(setProductFilter({
                                                ...productState.filter,        // dispatch(setProductFilter({
                                                stock: "",
                                            }));
                                        }}
                                        rangeComplete={(e) => {
                                            dispatch(setProductFilter({
                                                ...productState.filter,        // dispatch(setProductFilter({
                                                stock: e,
                                            }));

                                        }}
                                    >

                                    </CustomInputRange>
                                </div>

                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop: -50}}>
                        <Col md="12">
                            <div className="product-filter pb-0">
                                <CustomInputRange
                                    title="Price"
                                    maxRange={500}
                                    stateValue={productState.filter.price}
                                    clearRange={() => {
                                        dispatch(setProductFilter({
                                            ...productState.filter,        // dispatch(setProductFilter({
                                            price: "",
                                        }));
                                    }}
                                    rangeComplete={(e) => {
                                        dispatch(setProductFilter({
                                            ...productState.filter,        // dispatch(setProductFilter({
                                            price: e,
                                        }));

                                    }}
                                >

                                </CustomInputRange>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop:-20}}>
                        <Col md="12">
                            <div className="mr-1">
                                <div className="product-filter pb-0">
                                    <h6 className="f-w-600">Empty Field Check
                                    </h6>
                                </div>
                                <Input type="select" name="select" onChange={(e) => {
                                    dispatch(setProductFilter({
                                        ...productState.filter,
                                        null_check: e.target.value ? {
                                            id: e.target.value,
                                            name: e.target.options[e.target.selectedIndex].text
                                        } : ""
                                    }))
                                }} className="form-control digits" value={productState.filter.null_check?.id}>
                                    <option value="">Select Field</option>
                                    <option value="1-description">Description</option>
                                    <option value="1-summary">Summary</option>
                                    <option value="2-tag">Tag</option>
                                    <option value="1-custom_tags">Handel</option>
                                    <option value="1-taxonomy_id">Standard Product Type</option>
                                    <option value="2-style">Style</option>
                                    <option value="1-commodity_id">Commodity</option>
                                    <option value="1-weight_value">Weight</option>
                                    <option value="1-hs_code">Hs Code</option>
                                    <option value="2-composition">Composition</option>
                                    <option value="1-sale_price">Sale Price</option>


                                </Input>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md="12">
                            <Nav tabs className="border-tab">
                                <NavItem><NavLink
                                    className={productState.filter.offer_type==="" ? "active" : ""}
                                    onClick={() => {
                                        dispatch(setProductFilter({
                                            ...productState.filter,        // dispatch(setProductFilter({
                                            offer_type: "",
                                        }));
                                    }}> ALL</NavLink></NavItem>
                                <NavItem><NavLink
                                    className={productState.filter.offer_type?.id === 1 ? "active" : ""}
                                    onClick={() => {
                                        dispatch(setProductFilter({
                                            ...productState.filter,        // dispatch(setProductFilter({
                                            offer_type: {id:1,name:"Yes"}
                                        }));
                                    }}>Sales </NavLink></NavItem>
                                <NavItem><NavLink
                                    className={productState.filter.offer_type?.id === 2 ? "active" : ""}
                                    onClick={() => {
                                        dispatch(setProductFilter({
                                            ...productState.filter,        // dispatch(setProductFilter({
                                            offer_type: {id:2,name:"No"}
                                        }));
                                    }}> Non</NavLink></NavItem>
                            </Nav>
                        </Col>
                    </Row>

                </ScrollArea>
            </div>

        </>
    );
}

export default ProductFilter;