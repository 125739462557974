import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    addProductApi, addProductImageApi, deleteProductApi, getProductApi,
    getProductCreateSetting, getProductsApi,
    getProductSetting, getSubCategoryByCategoryId, sortProductImageApi, updateProductApi
} from "../../../services/manage-product/productService";
import {toast} from "react-toastify";
import {
    getInventoryProductsApi,
    getInventoryProductVariantsApi, updateInventoryApi
} from "../../../services/manage-product/inventoryService";



export const getInventoryProducts = createAsyncThunk('inventory/getProducts', async (data,{dispatch}) => {
    const response = await getInventoryProductsApi(data);
    dispatch(setInventoryProductPagination(response.response.pagination));
    return response.response.data;
});

export const updateInventory = createAsyncThunk('inventory/update', async (product, { dispatch, getState }) => {
    try {
        const response = await updateInventoryApi(product.variation,product.id);
        const data = await response.response;
        toast.success("Update inventory successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});






const inventoryAdapter = createEntityAdapter({});

export const { selectAll: selectInventory, selectById: selectInventoryProductId } = inventoryAdapter.getSelectors(
    state => state.product.inventory
);

const inventorySlice = createSlice({
    name: 'inventories',
    initialState: inventoryAdapter.getInitialState({
        searchText: '',
        pagination:{
            count:1,
            total:1,
            page_total:1
        },
        productVariants:[]
    }),
    reducers: {
        setInventoryProductPagination: (state, action) => {
            state.pagination = action.payload
        },
        setProductVariants:(state,action)=>{
            state.productVariants = action.payload
        }

    },
    extraReducers: {
        [getInventoryProducts.fulfilled]: inventoryAdapter.setAll,
        // [updateStaff.fulfilled]: staffsAdapter.setAll
        // [deleteStaff.fulfilled]: staffsAdapter.removeOne,
    }
});

export const {setInventoryProductPagination,setProductVariants} =
    inventorySlice.actions;

export default inventorySlice.reducer;