import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom'
import Breadcrumb from '../../../layout/breadcrumb'
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Button,
    Input,
    FormGroup,
    Form,
    Nav,
    NavItem,
    NavLink,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    ButtonDropdown,
    DropdownToggle,
    ListGroup,
    Modal,
    ModalFooter,
    ModalBody,
    ModalHeader,
    TabContent,
    TabPane,
    Media,
    Badge,

} from 'reactstrap'

import {useDispatch, useSelector} from "react-redux";
import withReducer from "../../../store/withReducer";
import productReducer from "../../manage-product/store";
import ModalFormSubmit from "../../../share-components/form/modalFormSubmit";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {
    openNewDialog,
    closeNewDialog,
    getProductSettings,
    productLookBook,
    openLookBookDialog,
    closeLookBookDialog,
    addProduct,
    setProductIndexSettings,
    getProduct,
    getProducts,
    selectProduct,
    bulkProductPublish,
    setProductFilter,
    addProductLookBook, setActionDialog
} from "../store/prodcutSlice";
import CreateForm from "./create";
import LookBookForm from "./look-book/lookBookForm";

import CardListing from "./content/cardListing";
import {productShow} from "./edit/content/settings/calculation";
import AsyncSelect from "react-select/async";
import {mapSearchAutoSuggestion} from "../../../utils/mapper";
import {sellerAutoSuggestionApi, sellerBrandSuggestionApi} from "../../../services/manage-product/productService";
import PreLoader from "../../../share-components/ui/preLoader";
import {CheckCircle, Info, MoreHorizontal, Target, Delete, RefreshCcw, Grid, List, Music} from "react-feather";
import {arrayToStringWithComma, filerValues, getFilterValues, moreFilterFields} from "./edit/content/settings/map";
import _ from "../../../@lodash";

import ProductFilter from "./content/filter";
import moment from 'moment';
import ProductAction from "./content/productAction";


const defaultValues = {
    brand: '',
    code: '',
    name: '',
    seller: {value: 1, label: "City Goddess Ltd"}
};

const defaultValuesLookBook = {
    title: '',
    sub_title: '',
    heading: '',
    email: '',
    name: '',
    pdfImage: '',
    template: '',
    dispatch_date: null,
    is_pre_order: false,
    rrp_price: '',
};
const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    }),
    menuPortal: base => ({...base, zIndex: 100})
};
const dataMenu = [
    {
        type: 0,
        text: 'Products',
    }
];

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    brand: yup.object().shape({
        id: yup.string(),
    }).nullable()
        .required('You must select a brand.'),
    name: yup.string().required('You must enter Product Name'),
    code: yup.string().required('You must enter style/sku code')
});

const schemaLookBook = yup.object().shape({
    title: yup.string().required('You must enter title'),
    exchange_rate: yup.string().required('You must enter exchange rate'),
    rrp_price: yup.string().required('You must select RRP/Price '),
});
const submitTypes = ["submit", "update"];
const Product = (props) => {
    // const location = useLocation();
    // console.log(location);
    const dispatch = useDispatch();
    let history = useHistory();
    let selectAllRef = useRef();
    const [timer, setTimer] = useState(null);
    let selectProductRef = useRef([]);
    const [selectedProductId, setSelectedProductId] = useState([]);
    const [selectedPage, setSelectedPage] = useState([]);
    const [differentPage, setDifferentPage] = useState(!submitTypes.includes(history.location.state?.from));


    const [actionDropdownOpen, setActionDropdownOpen] = useState(false);
    const actionToggle = () => setActionDropdownOpen(prevState => !prevState);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);
    const productState = useSelector(({product}) => product.product);
    const product = useSelector(selectProduct);
    // console.log(history.location.state?.from,product.length);
    // let isFetched= ( history.location.state?.from !== "update" || product.length <1);
    const [loading, isSetLoading] = useState(history.location.state?.from !== "update" || product.length < 1);
    const [isFilterable, setIsFilterable] = useState((history.location.state?.from !== "update" || product.length < 1));
    const {handleSubmit, formState, reset, control, register, setValue, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {
        handleSubmit: handleSubmitLookBook,
        formState: formLookBook,
        reset: resetLookBook,
        register: registerLookBook,
        setValue: setValueLookBook,
        getValues: getValuesLookBook
    } = useForm({
        mode: 'onChange',
        defaultValues: defaultValuesLookBook,
        resolver: yupResolver(schemaLookBook)
    });
    const [buttonDisable, setButtonDisable] = useState(false);
    const [templateImage, setTemplateImage] = useState([]);
    const {errors: errorsLookBook} = formLookBook;
    const getSellerBrands = (id, type = 1) => {

        dispatch(getProductSettings({seller_id: id, type})).then(res => {
            const payload = res.payload;
            console.log({...productState.productIndexSettings, ...payload}, payload);
            if (!res.error) dispatch(setProductIndexSettings({...productState.productIndexSettings, ...payload}))
        });
    }
    useEffect(() => {

        if (!submitTypes.includes(history.location.state?.from)) {
            dispatch(setProductFilter(filerValues()));
        }
    }, []);

    const productFilter=()=>{
        if (isFilterable) {
            const filter = productState.filter;
            console.log(differentPage, filter, filerValues(), _.isEqual(filter, filerValues(),), JSON.stringify(filter) == JSON.stringify(filerValues()));
            if (differentPage && JSON.stringify(filter) != JSON.stringify(filerValues())) {
                console.log("gaga");
                setDifferentPage(false);
            } else {
                dispatch(getProducts({
                    page_size: filter.pageSize,
                    page: filter.currentPage + 1,
                    name: filter.name,
                    brand: productState.filter.brand?.value,
                    status: productState.filter.status,
                    sort: productState.filter.sort,
                    seller_id: productState.filter.seller?.value,
                    category: productState.filter.category?.id,
                    sub_category: productState.filter.subCategory?.id,
                    country: productState.filter.country?.id,
                    season: productState.filter.season?.id,
                    commodity: productState.filter.commodity?.id,
                    colour: productState.filter.colour?.id,
                    null_check: productState.filter.null_check?.id,
                    stock: productState.filter.stock ? productState.filter.stock.min + "," + productState.filter.stock.max : "",
                    price: productState.filter.price ? productState.filter.price.min + "," + productState.filter.price.max : "",
                    tags: productState.filter.tags ? arrayToStringWithComma(productState.filter.tags).id : "",
                    styles: productState.filter.styles ? arrayToStringWithComma(productState.filter.styles).id : "",
                    compositions: productState.filter.compositions ? arrayToStringWithComma(productState.filter.compositions).id : "",
                    variant_type: productState.filter.variant_type?.id,
                    offer_type: productState.filter.offer_type?.id

                })).then(res => {
                    isSetLoading(false);
                })
                setDifferentPage(false);
            }
        }
        setIsFilterable(true);
    }

    useEffect(() => {
            productFilter();
    }, [productState.filter.currentPage, productState.filter.name, productState.filter.brand, productState.filter.sort, productState.filter.null_check,
        productState.filter.status, productState.filter.seller, productState.filter.trigger, productState.filter.pageSize, productState.filter.price,
        productState.filter.category, productState.filter.subCategory, productState.filter.stock, productState.filter.tags, currentUser.notifications,
        productState.filter.country, productState.filter.commodity, productState.filter.season, productState.filter.styles, productState.filter.compositions,
        productState.filter.variant_type,  productState.filter.colour,productState.filter.offer_type
    ])

    const {errors} = formState
    const closeDialog = () => {
        setValue('brand', {});
        setValue('seller', {});
        dispatch(closeNewDialog());
    }

    const closeLookDialog = () => {
        resetLookBook(defaultValuesLookBook);
        dispatch(closeLookBookDialog());
    }

    const onSubmitForm = (dialog, data) => {
        setButtonDisable(true);
        // alert(JSON.stringify(data));
        data.seller_brand_id = data.brand.id;
        data.seller = data.seller.value;
        delete data['brand']
        dispatch(addProduct({...data})).then(res => {
            setButtonDisable(false);
            if (!res.error) {
                const productId = res.payload.id;
                history.push('product/' + productId + '/edit')
                dispatch(closeNewDialog());
            }

        });

    }

    const onSubmitLookBookForm = (dialog, data) => {
        setButtonDisable(true);
        
        data.product_ids = selectedProductId;
        // data.more_option = Object.values(data.more_option);
        // console.log(JSON.stringify(data));
        let more_option = [];
        (data.more_option).map((item, i) => {
            more_option.push(item.value)
        });
        data.more_option = more_option;
        data.dispatch_date = data.dispatch_date ? moment(data.dispatch_date).format('YYYY-MM-DD') : null;

        dispatch(addProductLookBook({...data})).then(res => {
            setTimeout(() => {
                dispatch(closeLookBookDialog());
                setButtonDisable(false);
                window.open(`/look-book/product-look-book/${res.payload.id}`, "_blank");

            }, 3000);

        });

    }

    const pageChange = (event) => {
        dispatch(setProductFilter({...productState.filter, currentPage: event.selected}))
    }
    const sellerPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)))
            });
        });
    const typePromiseOption = (inputValue, seller) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue,
                seller_id: seller
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(sellerBrandSuggestionApi(data)))
            });
        });


    const selectAllOnChange = (e) => {
        selectProductChange(e.target.checked);

    }

    const selectProductChange = (isChecked) => {
        const selectedProductArray = [...selectedProductId];
        const selectedPages = [...selectedPage];
        const current = productState.filter.currentPage;
        product.map(function (item, key) {
            // selectProductRef.current[item.id].checked = e.target.checked;
            let productIndex = selectedProductArray.findIndex(aItem => aItem === item.id);
            if (productIndex != -1) {
                if (!isChecked) {
                    selectedProductArray.splice(productIndex, 1);
                }
            } else {
                selectedProductArray.push(item.id);
            }
        });
        let pageIndex = selectedPages.findIndex(aItem => aItem === current);
        if (isChecked) {
            if (pageIndex == -1) {
                selectedPages.push(current);
            }
        } else {
            if (pageIndex == -1) {
                selectedPages.splice(pageIndex, 1);
            }
        }
        setSelectedPage(selectedPages);
        setSelectedProductId(selectedProductArray);
    }

    useEffect(() => {

        if (selectAllRef.current) {
            const current = productState.filter.currentPage;
            if (selectAllRef.current.checked) {
                if (!selectedPage.includes(current)) {
                    selectAllRef.current.checked = false;
                }
            } else {
                if (selectedPage.includes(current)) {
                    selectAllRef.current.checked = true;
                }
            }
        }

    }, [product])

    const bulkProductAction = (type) => {
        dispatch(bulkProductPublish({product_ids: selectedProductId, type: type})).then(res => {
            setSelectedProductId([]);
            if (type != 1) {
                dispatch(setProductFilter({
                    ...productState.filter,
                    trigger: !productState.filter.trigger,
                    currentPage: 0
                }));
            }

        });
    }


    const LayoutView = (layoutColumns) => {
        dispatch(setProductFilter({...productState.filter, gridColumnNo: layoutColumns}))
    }

    const setStatus = (statusValue) => {
        // console.log(statusValue);
        dispatch(setProductFilter({...productState.filter, status: statusValue, currentPage: 0}));
        // setSelectedProductId([]);
    }
    const onPageSizeChange = (e) => {
        dispatch(setProductFilter({...productState.filter, currentPage: 0, pageSize: e.target.value}))
    }
    const clearFilter = () => {
        let productField = {...productState.filter};
        moreFilterFields().map(function (item, key) {
            productField[item.key] = "";
        });
        console.log(productField);
        dispatch(setProductFilter(productField))

    }
    const filterState = productState.filter;


    return (
        <Fragment>
            {loading ? <>
                    <PreLoader></PreLoader>
                </>
                : <>
                    <Breadcrumb title="Products" data={dataMenu}/>
                    <Container fluid={true} className="product-wrapper">
                        <div className="product-grid">

                            <div className="feature-products">
                                {(currentUser.permission).includes('Create Product') && <Row>
                                    <Col sm="12">
                                        <Card>
                                            <Row>
                                                <Col sm="6">
                                                    <div className='p-3'>
                                                        <div className="flex" style={{display: "inline-flex"}}>
                                                            <a onClick={() => {
                                                                selectAllRef.current.click()
                                                            }} className="btn btn-outline-primary text-left btn-square">
                                                                <input style={{pointerEvents: "none"}}
                                                                       onChange={selectAllOnChange} ref={(ref) => {
                                                                    selectAllRef.current = ref
                                                                }} type="checkbox"/> Select All
                                                                ({selectedProductId.length})
                                                            </a>
                                                            {selectedProductId.length > 0 &&

                                                                <ButtonDropdown isOpen={actionDropdownOpen}
                                                                                toggle={actionToggle}>
                                                                    <DropdownToggle caret color="primary"
                                                                                    className="btn btn-primary btn-square">
                                                                        Action
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                        <DropdownItem onClick={() => {
                                                                            bulkProductAction(1)

                                                                        }
                                                                        }>Publish</DropdownItem>
                                                                        <DropdownItem onClick={() => {
                                                                            bulkProductAction(2)

                                                                        }
                                                                        }>{productState.filter.status == 5 ? "Unarchive" : "Archive"}</DropdownItem>
                                                                        <DropdownItem onClick={() => {
                                                                            let obj = {props: {isOpen: true}};
                                                                            dispatch(openLookBookDialog(obj))

                                                                        }
                                                                        }>Look Book</DropdownItem>
                                                                         <DropdownItem onClick={() => {
                                                                             getSellerBrands("",3);
                                                                             let obj = {props: {isOpen: true},type:1,title:'Sales Offer'};
                                                                             dispatch(setActionDialog(obj))

                                                                        }
                                                                        }>Sales Offer</DropdownItem>
                                                                    </DropdownMenu>
                                                                </ButtonDropdown>
                                                            }
                                                            <div className="grid-options d-inline-block">
                                                                <ListGroup as="ul">

                                                                    <li>
                                                                        <a className="product-4-layout-view"
                                                                           onClick={() => LayoutView(3)}>
                                                                            <span
                                                                                className="line-grid line-grid-6 bg-primary"></span>
                                                                            <span
                                                                                className="line-grid line-grid-7 bg-primary"></span>
                                                                            <span
                                                                                className="line-grid line-grid-8 bg-primary"></span>
                                                                            <span
                                                                                className="line-grid line-grid-9 bg-primary"></span>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a className="product-6-layout-view"
                                                                           onClick={() => LayoutView(2)}>
                                                                            <span
                                                                                className="line-grid line-grid-10 bg-primary"></span>
                                                                            <span
                                                                                className="line-grid line-grid-11 bg-primary"></span>
                                                                            <span
                                                                                className="line-grid line-grid-12 bg-primary"></span>
                                                                            <span
                                                                                className="line-grid line-grid-13 bg-primary"></span>
                                                                            <span
                                                                                className="line-grid line-grid-14 bg-primary"></span>
                                                                            <span
                                                                                className="line-grid line-grid-15 bg-primary"></span>
                                                                        </a>
                                                                    </li>
                                                                </ListGroup>
                                                            </div>
                                                        </div>


                                                    </div>

                                                </Col>
                                                <Col sm="6">

                                                    <div className='float-sm-right p-3'>

                                                        <a onClick={() => {
                                                            dispatch(openNewDialog())
                                                        }} className="btn btn-sm btn-primary"> <i
                                                            className="fa fa-plus"></i> Add
                                                            Product</a>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </Card>
                                    </Col>
                                </Row>}
                                <Row>
                                    <Col md="7" className="project-list">
                                        <Nav tabs className="border-tab">
                                            <NavItem><NavLink
                                                className={productState.filter.status === 0 ? "active" : ""}
                                                onClick={() => {
                                                    setStatus(0);
                                                }}><Target/> ALL</NavLink></NavItem>
                                            <NavItem><NavLink
                                                className={productState.filter.status === 1 ? "active" : ""}
                                                onClick={() => {
                                                    setStatus(1);
                                                }}><MoreHorizontal color="#f8d62b"/> Draft </NavLink></NavItem>
                                            <NavItem><NavLink
                                                className={productState.filter.status === 2 ? "active" : ""}
                                                onClick={() => {
                                                    setStatus(2);
                                                }}><Info color="#7366ff"/> Pending</NavLink></NavItem>
                                            <NavItem><NavLink
                                                className={productState.filter.status === 3 ? "active" : ""}
                                                onClick={() => {
                                                    setStatus(3)
                                                }}><CheckCircle color="#51bb25"/> Published</NavLink></NavItem>
                                            <NavItem><NavLink
                                                className={productState.filter.status === 4 ? "active" : ""}
                                                onClick={() => {
                                                    setStatus(4)
                                                }}><RefreshCcw color="#a927f9"/>Re Published</NavLink></NavItem>
                                            <NavItem><NavLink
                                                className={productState.filter.status === 5 ? "active" : ""}
                                                onClick={() => {
                                                    setStatus(5);
                                                }}><Delete color="#dc3545"/>Archive</NavLink></NavItem>


                                        </Nav>
                                    </Col>
                                    <Col md="5" className="text-right">
                                <span
                                    className="f-w-600 m-r-5">Showing Products {productShow(productState.filter.currentPage, 8, productState.pagination.page_total,)} of {productState.pagination.total} Results</span>
                                        <div className="select2-drpdwn-product select-options d-inline-block">
                                            <select defaultValue={productState.filter.sort}
                                                    className="form-control btn-square" name="select" onChange={(e) => {
                                                dispatch(setProductFilter({
                                                    ...productState.filter,
                                                    sort: e.target.value,
                                                    currentPage: 0
                                                }));
                                            }}>
                                                <option value="id-desc">Created (newest first)</option>
                                                <option value="id-asc">Created (oldest first)</option>
                                                <option value="name-asc">A-Z</option>
                                                <option value="name-desc">Z-A</option>
                                                <option value="price-asc">Price Low To High</option>
                                                <option value="price-desc">Price High To Low</option>
                                                <option value="total_quantity-asc">Quantity Low To High</option>
                                                <option value="total_quantity-desc">Quantity High To Low</option>
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    {currentUser.role.id != 5 && <Col xl={3} sm={12}>
                                        <AsyncSelect
                                            className="select2-filter"
                                            isClearable={true}
                                            menuPortalTarget={document.body}
                                            // styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                            defaultOptions
                                            placeholder="select Seller"
                                            defaultValue={productState.filter.seller}
                                            loadOptions={sellerPromiseOption}
                                            styles={customStyles}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            onClear={(e) => {

                                            }}
                                            onChange={(e) => {
                                                dispatch(setProductFilter({
                                                    ...productState.filter,
                                                    seller: e ? e : null,
                                                    currentPage: 0
                                                }));
                                            }}

                                        />
                                    </Col>}
                                    <Col xl="3" sm="12">

                                        <AsyncSelect
                                            cacheOptions={true}
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isClearable="true"
                                            defaultOptions
                                            placeholder="select Brand"
                                            loadOptions={(e) => {
                                                return typePromiseOption(e, productState.filter.seller?.value)
                                            }
                                            }
                                            defaultValue={productState.filter.brand}
                                            styles={customStyles}
                                            menuPortalTarget={document.body}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            onClear={(e) => {

                                            }}
                                            onChange={(e) => {
                                                dispatch(setProductFilter({
                                                    ...productState.filter,
                                                    brand: e ? e : null,
                                                    currentPage: 0
                                                }));
                                            }}

                                        />

                                    </Col>
                                    <Col xl={currentUser.role.id != 5 ? "4" : "7"} sm="12">
                                        <Form>
                                            <FormGroup className="m-0">
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={productState.filter.name}
                                                    placeholder="search Product Name / Style"
                                                    onChange={(e) => {
                                                        clearTimeout(timer)

                                                        const newTimer = setTimeout(() => {
                                                            dispatch(setProductFilter({
                                                                ...productState.filter,
                                                                name: e.target.value,
                                                                currentPage: 0
                                                            }));
                                                        }, 500)

                                                        setTimer(newTimer)

                                                    }}

                                                />
                                                <i className="fa fa-search"></i>
                                            </FormGroup>
                                        </Form>


                                    </Col>
                                    <Col md={2}>
                                        <div className={`product-sidebar`}>
                                            <div className="filter-section">
                                                <Card>
                                                    <CardHeader>
                                                        <h6 className="mb-0 f-w-600" onClick={() => {
                                                            getSellerBrands("");
                                                            document.querySelector(".customizer-contain").classList.add('open');
                                                        }
                                                        }>Filters
                                                            <span className="pull-right">
                                                                <i className="fa fa-chevron-right toggle-data"></i>
                                                              </span>
                                                        </h6>
                                                    </CardHeader>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardBody>
                                        <div className="mb-3">
                                            {moreFilterFields().map((item, i) =>
                                                    filterState[item.key] && <Badge key={i}
                                                                                    color="light">{item.name} {getFilterValues(item.type, filterState[item.key])}
                                                        <a className="btn-xs btn-default" onClick={() => {
                                                            let obj = {...productState.filter};
                                                            obj[item.key] = "";
                                                            dispatch(setProductFilter(obj));

                                                        }}><i className="fa fa-close"></i></a>
                                                    </Badge>
                                            )}
                                        </div>
                                        <CardListing
                                            selectedProductIds={selectedProductId}
                                            setselectedProductIds={setSelectedProductId}
                                            data={product}
                                            productState={productState}
                                            totalCount={productState.pagination.count}
                                            pageChange={pageChange}
                                            onPageSizeChange={onPageSizeChange}
                                            currentPage={productState.filter.currentPage}
                                            history={history}
                                            selectProductRef={selectProductRef}
                                        >
                                        </CardListing>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <div className="customizer-contain">
                                <div className="customizer-header">
                                    <i className="icon-close" onClick={() => {
                                        document.querySelector(".customizer-contain").classList.remove('open');
                                    }
                                    }></i>
                                    <h5>{"Filters      "} <a className="a-text-color ml-2"> <i className="fa fa-refresh"
                                                                                               title="clear filters"
                                                                                               onClick={() => {
                                                                                                   clearFilter();
                                                                                               }
                                                                                               }></i></a></h5>

                                    <ProductFilter customStyles={customStyles}
                                                   productState={productState}></ProductFilter>
                                </div>
                            </div>
                        </Row>
                    </Container>
                    <ModalFormSubmit
                        index={1}
                        defaultValues={defaultValues}
                        schema={schema}
                        onSubmitForm={onSubmitForm}
                        reset={reset}
                        formState={formState}
                        handleSubmit={handleSubmit}
                        title='Product'
                        selector='staff'
                        dialog={({product}) => product.product.addDialog}
                        closeDialog={closeDialog}
                        buttonDisable={buttonDisable}
                    >
                        <CreateForm control={control} errors={errors} register={register} setValue={setValue}
                                    trigger={trigger}
                                    currentUser={currentUser}
                                    brands={productState.productIndexSettings.brands} getValues={getValues}
                                    typePromiseOption={sellerPromiseOption}
                                    getSellerBrands={getSellerBrands}></CreateForm>
                    </ModalFormSubmit>

                    <ModalFormSubmit
                        index={2}
                        defaultValues={defaultValuesLookBook}
                        schema={schemaLookBook}
                        onSubmitForm={onSubmitLookBookForm}
                        reset={resetLookBook}
                        formState={formLookBook}
                        handleSubmit={handleSubmitLookBook}
                        title='Look Book'
                        selector='staff'
                        dialog={({product}) => product.product.addLookBookDialog}
                        closeDialog={closeLookDialog}
                        buttonDisable={buttonDisable}
                    >
                        <LookBookForm control={control} errors={errorsLookBook} register={registerLookBook}
                                      setValue={setValueLookBook}
                                      getValues={getValuesLookBook}></LookBookForm>
                    </ModalFormSubmit>
                    <ProductAction setSelectedPage={setSelectedPage} selectAllRef={selectAllRef} productFilter={productFilter} selectedProductId={selectedProductId} setSelectedProductId={setSelectedProductId} state={productState}>
                    </ProductAction>
                </>}

        </Fragment>
    );

};

export default withReducer([{product: productReducer}])(Product);


