import {useDispatch, useSelector} from "react-redux";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, FormGroup, Nav, Row} from "reactstrap";
import ColoredLine from "../../../../share-components/ui/coloredLine";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import {mapSettingResponseToTable} from "../../../../utils/mapper";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import SettingForm from "../settingForm";
import {
    addCompositionGroup,
    closeEditDialog,
    deleteCompositionGroup,
    getAllCompositionGroups,
    openEditDialog,
    openNewDialog,
    selectCompositionGroup,
    updateCompositionGroup
} from "../../store/compositionGroupSlice";
import withReducer from "../../../../store/withReducer";
import productReducer from "../../store";
import {Form} from "react-bootstrap";
import PreLoader from "../../../../share-components/ui/preLoader";

const defaultValues = {
    id: '',
    name: '',
    publish_at: ''
};

const dataMenu = [
    {
        type: 0,
        text: 'Products',
        link: '/product'
    },
    {
        type: 1,
        text: 'Product Setting',
        link: ''
    },
    {
        type: 1,
        text: 'Product Materials',
        link: ''
    }
];

const schema = yup.object().shape({
    name: yup.string().required('You must enter a product type name'),
});

const CompositionGroup = () => {
    const dispatch = useDispatch();
    const [name, setName] = useState([]);
    const [loading, setLoading] = useState(true);
    const compositionGroup = useSelector(selectCompositionGroup);
    const [buttonDisable, setButtonDisable] = useState(false);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);

    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {errors} = formState;

    const tableColumns = [
        {
            name: 'Material Name',
            selector: row => row.name,
            sortable: true,
            left: true,
        },
    ];

    useEffect(() => {
        const data = {
            name
        }
        dispatch(getAllCompositionGroups(data)).then(res=>{
            if (!res.error)
            setLoading(false);
        });
    }, [dispatch, name]);

    const onSubmitForm = (dialog, data) => {
        setButtonDisable(true);
        if (dialog.type === 'new') {
            dispatch(addCompositionGroup({...data})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type)
            });
        } else {
            dispatch(updateCompositionGroup({...data})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type);
            });
        }
    }

    const closeDialog = () => {
        dispatch(closeEditDialog());
    }

    const onEdit = (row) => {
        dispatch(openEditDialog(row));
    }

    const onDelete = (row) => {
        dispatch(deleteCompositionGroup(row.id));
    }

    let btnText = '';
    let publishValue = 0;
    let saveValue = 0;
    if (getValues) {
        if (getValues('publish_at') == 1) {
            btnText = 'Save & Unpublish';
            publishValue = 0;
            saveValue = 1;
        } else {
            btnText = 'Save & Publish';
            publishValue = 1;
            saveValue = 0;
        }
    }

    return (
        <Fragment>

            <Container fluid={true} className="product-wrapper">
                {loading ? <><PreLoader></PreLoader></> : <>
                    <Breadcrumb data={dataMenu} title="Materials"/>
                    <div className="product-grid">
                        <div className="feature-products">
                            <Row>
                                <Col sm="12">
                                    {(currentUser.permission).includes('Create Composition Group') ?
                                    <Card>
                                        <Row>
                                            <Col sm="6">
                                                <Nav tabs className="border-tab">

                                                </Nav>
                                            </Col>
                                            <Col sm="6">
                                                <div className='float-sm-right p-3'>
                                                    <a className="btn btn-sm btn-primary"
                                                       onClick={() => {
                                                           dispatch(openNewDialog())
                                                       }}
                                                    > <i className="fa fa-plus"></i> Add Materials</a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                        :''}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" xl="12">
                                    <Form>
                                        <FormGroup>
                                            <input onChange={(e) => setName(e.target.value)} name="name"
                                                   type="text" className="form-control border"
                                                   placeholder="Search By Product Materials Name"/>
                                            <i className="fa fa-search"></i>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <Card>
                                        <CardBody>
                            <div className="table-responsive product-table mt-4">
                                <CommonDataTable
                                    headerColumns={tableColumns}
                                    gridData={mapSettingResponseToTable(compositionGroup)}
                                    pagination
                                    onEdit={(currentUser.permission).includes('Update Composition Group') ? onEdit:''}
                                    onDelete={(currentUser.permission).includes('Delete Composition Group') ? onDelete:''}
                                />
                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </div> </>
                }
            </Container>
            <ModalFormSubmit
                defaultValues={defaultValues}
                schema={schema}
                onSubmitForm={onSubmitForm}
                reset={reset}
                formState={formState}
                handleSubmit={handleSubmit}
                title='Composition Group'
                selector='product'
                dialog={({product}) => product.compositionGroup.addDialog}
                closeDialog={closeDialog}
                buttonDisable={buttonDisable}
            >
                <SettingForm
                    control={control} formText="Composition Group" errors={errors} register={register}
                    setValue={setValue} trigger={trigger} getValues={getValues}
                />
            </ModalFormSubmit>
        </Fragment>
    )

}

export default withReducer([{compositionGroup: productReducer}])(CompositionGroup);