import {Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment} from "react";
import AsyncSelect from "react-select/async";


const AgentForm = (props) => {
    const { errors, control, register,setValue,trigger,getValues,searchSellersByName,sellers,setOptions,promiseOptions } = props;
    const arr = [];
    return (
        <Fragment>
            <Row>
                <Col sm="12" className="m-b-30">
                        <Row>
                        <Col>
                            <FormGroup>
                                <Label className="col-form-label">Agent Name</Label>
                                <input name="name" type="text" {...register('name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`}/>
                                <div className="invalid-feedback">{errors.name?.message}</div>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label className="col-form-label">Agent Email</Label>
                                <input name="email" type="email" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`}/>
                                <div className="invalid-feedback">{errors.email?.message}</div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label className="col-form-label">Contact Number</Label>
                                <input name="mobile_number" type="number" {...register('mobile_number')} className={`form-control ${errors.mobile_number ? 'is-invalid' : ''}`}/>
                                <div className="invalid-feedback">{errors.mobile_number?.message}</div>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label className="col-form-label">Agent commission</Label>
                                <input name="commission_percentage" type="number" {...register('commission_percentage')} className={`form-control ${errors.commission_percentage ? 'is-invalid' : ''}`}/>
                                <div className="invalid-feedback">{errors.commission_percentage?.message}</div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="col-form-label">Seller</Label>
                                <AsyncSelect
                                    isClearable
                                    isMulti
                                    cacheOptions
                                    defaultOptions
                                    defaultValue={getValues('seller')}
                                    loadOptions={promiseOptions}
                                    onChange={(e)=>{
                                        setValue('seller', e)
                                        trigger('seller');
                                    }}
                                />
                                <div className="invalid-feedback">{errors.role?.message}</div>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Fragment>
    );
};

export default AgentForm;