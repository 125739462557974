import React, {Fragment} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {Card, CardBody} from "reactstrap";
import {useSelector} from "react-redux";

const dataMenu = [
    {
        type: 0,
        text: 'Notifications',
        link: ''
    },

];

const Notifications = () => {

    const currentUser = useSelector(({authReducer}) => authReducer.data.user);
    console.log(currentUser.notifications)
    return (
        <Fragment>
            <Breadcrumb data={dataMenu} title="All Notifications"/>
            <Card>
                <CardBody>
                    <div className="todo">
                        <div className="todo-list-wrapper">
                            <div className="todo-list-container">
                                <div className="todo-list-body">
                                    <ul id="todo-list">
                                        {currentUser.notifications.length > 0 ?
                                            currentUser.notifications.map((todo, index) =>
                                                <li className={"task " + todo.status} key={index}>
                                                    <div className="task-container">
                                                        <h4 className="task-label">{todo.message}</h4>
                                                        <span className="float-right">{todo.time}</span>
                                                    </div>
                                                </li>
                                            ) : ''}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="notification-popup hide">
                            <p><span className="task"></span><span className="notification-text"></span></p>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
}

export default (Notifications)