import React, {Fragment, useState} from "react";
import {Col, FormGroup, Label, Row} from "reactstrap";
import AsyncSelect from "react-select/async";
import {FormCheck} from "react-bootstrap";
import Switch from "react-switch";


const ProductCategoryForm = (props) => {
    const {
        errors,
        control,
        register,
        setValue,
        trigger,
        getValues,
        searchSellersByName,
        sellers,
        setOptions,
        typePromiseOption,
        formText,
        categoryPromiseOption
    } = props;

    // console.log(getValues('default_config'))

    const [isParent, setIsParent] = useState(getValues('is_parent'));
    const [defaultConfig, setDefaultConfig] = useState(getValues('default_config'));

    const [checked, setChecked] = useState(false);
    const [defaultValue, setDefaultValue] = useState(getValues('default_config'));
    const [defVal, setDefVal] = useState(false);
    const handleChange = nextChecked => {
        console.log(nextChecked)
        nextChecked ? setValue('is_parent', nextChecked) : setValue('is_parent', false)

        setChecked(nextChecked);
        setDefaultValue(nextChecked);
        // setValue('is_parent', nextChecked)
        setIsParent(nextChecked)

    };

    const handleChange2 = nextChecked1 => {
        nextChecked1 ? setValue('default_config', false) : setValue('default_config', true)
        setChecked(nextChecked1);
        setDefaultConfig(nextChecked1)
    }

    const setCategory = (e) => {
        setValue('parent_category', e)
    }



    return (
        <Fragment>
            <Row>
                <Col>
                    <FormGroup>
                        <Label className="col-form-label">Type</Label>
                        <AsyncSelect
                            isClearable
                            cacheOptions
                            defaultOptions
                            defaultValue={getValues('type')}
                            loadOptions={typePromiseOption}
                            error={errors.type}
                            onChange={(e) => {
                                console.log(e)
                                setValue('type', e)
                                trigger('type');
                            }}
                            className={` ${errors.type ? 'is-invalid' : ''}`}

                        />
                        <div className="invalid-feedback">{errors.type?.message}</div>
                    </FormGroup>
                </Col>
            </Row>
            <Row style={{marginBottom: 15}}>
                <Col md="6">
                <label style={{marginTop: 8, marginLeft: 4}}>Is Child</label>
                <FormGroup style={{marginTop: 1, marginLeft: 4}}>
                    <Switch
                        checked={isParent}
                        onChange={handleChange}
                        className="react-switch"
                        uncheckedIcon={false}
                        checkedIcon={false}
                    />

                </FormGroup>
                </Col>
                {!isParent ?
                    <Col md="6">
                        <label style={{marginTop: 8, marginLeft: 4}}>Dress Configuration</label>
                        <FormGroup style={{marginTop: 1, marginLeft: 4}}>

                            <Switch
                                checked={defaultConfig}
                                onChange={handleChange2}
                                className="react-switch"
                                uncheckedIcon={false}
                                checkedIcon={false}
                            />

                        </FormGroup>
                    </Col>:''}

            </Row>
            <Row style={{marginBottom: 15}}>
            <Col>
                {isParent ? <FormGroup>
                    <Label className="col-form-label">Parent category</Label>
                    <AsyncSelect
                        isClearable
                        cacheOptions
                        defaultOptions
                        defaultValue={getValues('parent_category')}
                        loadOptions={categoryPromiseOption}
                        onChange={setCategory}
                        error={errors.parent_category}
                        className={` ${errors.parent_category ? 'is-invalid' : ''}`}

                    />
                    <div className="invalid-feedback">{errors.parent_category?.message}</div>
                </FormGroup> : ''}
            </Col>
        </Row>
    <Row>
        <Col>
            <FormGroup>
                <Label className="col-form-label">Product Category Name</Label>
                <input name="name" type="text" {...register('name')}
                       className={`form-control ${errors.name ? 'is-invalid' : ''}`}/>
                <div className="invalid-feedback">{errors.name?.message}</div>
            </FormGroup>
        </Col>
    </Row>
</Fragment>
)
    ;
};

export default ProductCategoryForm;