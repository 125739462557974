import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {getSellerReportDataApi} from "../../../../services/report";


export const getSalesReportData = createAsyncThunk('report/sales-report', async (data, {dispatch}) => {
    const response = await getSellerReportDataApi(data);
    dispatch(setTableRowCount(response.response.count))
    dispatch(setTotalAmount(response.response.total))
    return response.response.data;
});

const salesReportAdapter = createEntityAdapter({});

export const {selectAll: selectSalesReport, selectById: selectSalesReportById} = salesReportAdapter.getSelectors(
    state => state.report.salesReport
);

const salesReportSlice = createSlice({
    name: 'sellerReport',
    initialState: salesReportAdapter.getInitialState({
            id: null,
            searchText: '',
            salesReportTableRow:0,
            totalAmount:0,
        }),
    reducers: {
        setTableRowCount: (state, action) => {
            state.salesReportTableRow = action.payload;
        },
        setTotalAmount: (state, action) => {
            state.totalAmount = action.payload;
        },
    },
    extraReducers: {
        [getSalesReportData.fulfilled] : salesReportAdapter.setAll
    }
});

export const {setTableRowCount,setTotalAmount} = salesReportSlice.actions;

export default salesReportSlice.reducer;