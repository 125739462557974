import React, {Fragment, useEffect, useRef, useState} from "react";
import * as ReactDOM from 'react-dom';
import {Badge, Card, CardBody, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import Select from "react-select";
import JqxTable from "../../../../../share-components/table/JqxTable";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {setMediaDialog} from "../../../store/prodcutSlice";
import {Button} from "react-bootstrap";
import DropZone from "../../../../../share-components/upload/dropzone";
import ReactPaginate from "react-paginate";


const MediaModal = (props) => {
    const {setValue, trigger, getValues, imageSelectOnClick} = props;
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(0);
    const [isChange, setIsChanged] = useState(false);

    const productState = useSelector(({product}) => product.product.mediaDialogProps);


    const closDialog = () => {
        dispatch(setMediaDialog({props: {isOpen: false}}))
    }


    const image_id = productState.data ? productState.data.image_id : "";
    const colour_id = productState.data ? productState.data.colour_id : "";
    const images = (getValues('images').filter(function (v, i) {
        return v.media_type == 1;
    })).reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 6)

        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
    }, []);
    useEffect(() => {
        if (productState.props.isOpen) {

            setCurrentPage(findImageIndex(images));
        }

    }, [productState.props.isOpen])


    const findImageIndex = (images) => {
        let page = 0;
        images.map((item, i) => {
            item.map((subItem, subIndex) => {
                if (subItem.id === image_id) {
                    page = i;
                }
            });
        });
        return page;
    }


    return (
        <Fragment>
            <div className="form-row mb-3">
                <Modal size="lg"    {...productState.props} toggle={() => {
                    closDialog()
                }}>
                    <ModalHeader toggle={() => {
                        closDialog()
                    }}>

                    </ModalHeader>
                    <ModalBody>
                        <div className="form-row mb-3">
                            <Col md={12}>
                                <FormGroup>
                                    <Label className="col-form-label">Making Video Url</Label>
                                    <input name="making_video"
                                           defaultValue={getValues('color').find(x => x.id == colour_id)?.making_video}
                                           type="url" onChange={(e) => {

                                            let colourData = [];
                                            getValues('color').map((rationItem, rationIndex) => {
                                                if (rationItem.id == colour_id) {
                                                    rationItem.making_video =(e.target.value).match(/^https:\/\//)? e.target.value:"";
                                                }
                                                colourData.push(rationItem);
                                            });

                                            setValue('color', colourData);
                                            setIsChanged(!isChange);


                                    }}
                                           className={`form-control`}/>
                                </FormGroup>
                            </Col>
                        </div>
                        <div className="form-row mb-3" style={{justifyContent:'center'}}>
                            {getValues('color').find(x => x.id == colour_id)?.making_video && <iframe width="250" height="175" src={getValues('color').find(x => x.id == colour_id)?.making_video }>
                            </iframe>}
                        </div>
                        <div className="form-row mb-3">
                            {images.length >0   &&   <div style={{display: "contents"}}>
                                {images[currentPage].map((item, i) => item.media_type == 1 &&
                                    <Col md={4} index={i} data-id={item.id} key={i}>


                                        <Card>
                                            <div className="product-box variant-image" onClick={() => {
                                                imageSelectOnClick(productState.data.rowIndex, item.id, item.image_path);
                                            }}>
                                                <div className="product-img">
                                                    <div
                                                        className={`image-crop-center ${image_id == item.id ? 'active-variant-image' : ''}`}
                                                        style={{height: 200}}>
                                                        <img className="img-fluid scale-image-6" src={item.image_path}
                                                             alt=""/>
                                                    </div>
                                                </div>
                                                {/*<div className="product-details text-center">*/}
                                                {/*    <h6 className="font-primary" >test</h6>*/}
                                                {/*</div>*/}
                                            </div>
                                        </Card>


                                    </Col>)}
                            </div>}
                        </div>
                        <Row>
                            <Col md={12}>
                                <div style={{display: "inline-flex", float: "right"}}>

                                    <div>
                                        <ReactPaginate
                                            className="pagination"
                                            activeClassName="pagination-active"
                                            breakLabel="..."
                                            nextLabel="next >"
                                            onPageChange={(event) => {
                                                setCurrentPage(event.selected);
                                            }}
                                            pageRangeDisplayed={5}
                                            forcePage={findImageIndex(images)}
                                            pageCount={images.length}
                                            previousLabel="< previous"
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>

                </Modal>
            </div>
        </Fragment>
    );
}

function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps.getValues) == JSON.stringify(nextProps.getValues);
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
}

export default React.memo(MediaModal, areEqual);