import React, {Fragment, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {MENUITEMS} from './menu';
import {ArrowRight, ArrowLeft, Grid} from 'react-feather';
import {Link} from 'react-router-dom'
import {translate} from 'react-switch-lang';
import configDB from '../../data/customizer/config';
import {DefaultLayout} from '../theme-customizer';
import {selectNavigationAll} from "../../redux/auth/navigationSlice";
import {setSideMenuToggle} from "../../redux/customizer/themeSlice";


const Sidebar = (props) => {
    const id = window.location.pathname.split('/').pop()
    const defaultLayout = Object.keys(DefaultLayout);
    const layout = id ? id : defaultLayout
    // eslint-disable-next-line

    const [margin, setMargin] = useState(0);
    const [width, setWidth] = useState(0);
    const [sidebartoogle, setSidebartoogle] = useState(false)
    const themeData = useSelector(({theme}) => theme.theme);
    const menusSate = useSelector(selectNavigationAll);
    const [menus, setMainMenu] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const dispatch = useDispatch();


    const wrapper = themeData.customizer.settings.sidebar.type || configDB.data.settings.sidebar.type;
    const handleScroll = () => {

        if (window.scrollY > 400) {

            if (configDB.data.settings.sidebar.type.split(' ').pop() === 'material-type' || configDB.data.settings.sidebar.type.split(' ').pop() === 'advance-layout')
                document.querySelector(".sidebar-main").className = "sidebar-main hovered"
        } else {

            if (document.getElementById("sidebar-main"))
                document.querySelector(".sidebar-main").className = "sidebar-main"
        }
    }
    useEffect(() => {
        const currentUrl = window.location.pathname;
        if(menusSate.length >0) {
            const currentUrl = window.location.pathname;
            console.log(currentUrl);
            let allMenus=[];
            (menusSate[0].Items).map(menuItems => {
                allMenus.push(menuItems);
                if((typeof  menuItems.children !== 'undefined')) {
                    (menuItems.children).map(childMenus => {
                        allMenus.push(childMenus);
                        if((typeof  childMenus.children !== 'undefined')) {
                            (childMenus.children).map(subChildMenus => {
                                allMenus.push(subChildMenus);
                            });
                        }
                    });
                }
            });
            let currentITem = (allMenus).filter(function (value, index) {
                return value.path == currentUrl;
            });
            if(currentITem.length > 0) {
                setLoadActive(menusSate, currentITem[currentITem.length -1]);
            }else{
                let splitCurrentUrl=currentUrl.split("/");
                let currentITem = (allMenus).filter(function (value, index) {
                    return value.path == "/"+splitCurrentUrl[1]+"/"+splitCurrentUrl[2];
                });
                if(currentITem.length>0){
                    setLoadActive(menusSate, currentITem[currentITem.length -1]);
                }else{
                    let splitCurrentUrl=currentUrl.split("/");
                    let currentITem = (allMenus).filter(function (value, index) {
                        return value.path == "/"+splitCurrentUrl[1];
                    });
                    if(currentITem.length>0){
                        setLoadActive(menusSate, currentITem[currentITem.length -1]);
                    }else{
                        setMainMenu(menusSate);
                    }
                }

            }

        }
    }, [menusSate]);



    useEffect(() => {
        document.querySelector(".left-arrow").classList.add("d-none")
        window.addEventListener('resize', handleResize)
        handleResize();


        window.addEventListener('scroll', handleScroll)
        handleScroll();
        return () => {
            window.removeEventListener('scroll', handleScroll)
            window.removeEventListener('resize', handleResize)
        }

        // eslint-disable-next-line
    }, [layout]);

    const handleResize = () => {
        setWidth(window.innerWidth - 500);
    }

    const setNavActive = (item) => {
        setActive(menus,item);
    }
    const setLoadActive = (menu,item) => {
        setActive(menu,item);
    }
    const setActive = (menu,item) => {

        const menudata = [...menu];
        console.log(item);
        menudata.map((menuItems, index) => {
            const mainMenus = [...menuItems.Items]
            mainMenus.map((Items, ind) => {
                if(item.parent_id === 0) {
                    if (Items.id === item.id) {
                        mainMenus[ind] = {...Items, active: true};
                    } else {
                        if((typeof  Items.children !== 'undefined')) {
                            const childMenus = [...Items.children];
                            if (childMenus) {
                                childMenus.map((childItems, childIndex) => {
                                    childMenus[childIndex] = {...childItems, active: false};
                                });
                            }
                            mainMenus[ind] = {...Items, children: childMenus, active: false};
                        }else{
                            mainMenus[ind] = {...Items,active: false};
                        }
                    }
                }else{
                    if(Items.id == item.parent_id) {
                        const childMenus = [...Items.children]
                        childMenus.map((childItems, childIndex) => {
                            if (childItems.id === item.id) {
                                if((typeof  childItems.children !== 'undefined')) {
                                    const subChildMenus=[...childItems.children];
                                    (childItems.children).map((subItem, subIndex) => {
                                        if (subItem.sub_id === item.sub_id) {
                                            subChildMenus[subIndex] = {...subItem, active: true}
                                        }else{
                                            subChildMenus[subIndex] = {...subItem, active: false}
                                        }
                                    });
                                    childMenus[childIndex] = {...childItems,children: subChildMenus, active: !item.active};
                                }else {
                                    childMenus[childIndex] = {...childItems, active: true};
                                }
                            } else {
                                childMenus[childIndex] = {...childItems, active: false};
                            }
                        });
                        mainMenus[ind] = {...Items,children:childMenus, active: true};
                    }
                }
            })
            menudata[index] = {...menuItems, Items: mainMenus};
        })
        setMainMenu(menudata)

    }

    const toggletNavActive = (item) => {

    }

    const scrollToRight = () => {
        if (margin <= -2598 || margin <= -2034) {
            if (width === 492) {
                setMargin(-3570)
            } else {
                setMargin(-3464)
            }
            document.querySelector(".right-arrow").classList.add("d-none")
            document.querySelector(".left-arrow").classList.remove("d-none")
        } else {
            setMargin(margin => margin += (-width));
            document.querySelector(".left-arrow").classList.remove("d-none")
        }
    }

    const scrollToLeft = () => {
        if (margin >= -width) {
            setMargin(0)
            document.querySelector(".left-arrow").classList.add("d-none")
            document.querySelector(".right-arrow").classList.remove("d-none")
        } else {
            setMargin(margin => margin += width);
            document.querySelector(".right-arrow").classList.remove("d-none")
        }
    }

    const closeOverlay = () => {
        document.querySelector(".bg-overlay1").classList.remove("active")
        document.querySelector(".sidebar-link").classList.remove("active")
    }

    const activeClass = () => {
        // document.querySelector(".sidebar-link").classList.add("active")
        document.querySelector(".bg-overlay1").classList.add("active")
    }

    const openCloseSidebar = (toggle) => {

        if (toggle) {
            setSidebartoogle(!toggle);
            document.querySelector(".page-header").className = "page-header close_icon";
            document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon "
        } else {
            setSidebartoogle(!toggle);
            document.querySelector(".page-header").className = "page-header";
            document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper "
        }
        dispatch(setSideMenuToggle(!toggle))
    };

    const responsiveSidebar = () => {
        document.querySelector(".page-header").className = "page-header close_icon";
        document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon"
    }
    const domainName = window.location.origin;
    const logo = (domainName == "https://mybabezlondon.com") ? 'bl_london_logo.png' : 'logo-full-city-goddess.png';
    const smallLogo = (domainName == "https://mybabezlondon.com") ? 'BLsml.jpg' : 'cg-logo.png';
    return (
        <Fragment>
            <div className={`bg-overlay1`} onClick={() => {
                closeOverlay()
            }}></div>
            <div className="sidebar-wrapper" id="sidebar-wrapper">
                <div className="logo-wrapper">
                    <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                        <img width={150}  className="img-fluid for-light" src={require("../../assets/images/logo/"+logo)} alt=""/>
                        <img className="img-fluid for-dark" src={require("../../assets/images/logo/logo-full-city-goddess.png")}
                             alt=""/>
                    </Link>
                    <div className="back-btn" onClick={() => responsiveSidebar()}><i className="fa fa-angle-left"></i>
                    </div>
                    <div className="toggle-sidebar" onClick={() => openCloseSidebar(sidebartoogle)}><Grid
                        className="status_toggle middle sidebar-toggle"/></div>
                </div>
                <div className="logo-icon-wrapper">
                    <Link to={`${process.env.PUBLIC_URL}/dashboard/default/${layout}`}><img className="img-fluid" width={30}
                                                                                            src={require("../../assets/images/logo/"+smallLogo)}
                                                                                            alt=""/></Link>
                </div>
                <nav className="sidebar-main" id="sidebar-main">
                    <div className="left-arrow" onClick={scrollToLeft}><ArrowLeft/></div>
                    <div id="sidebar-menu"
                         style={wrapper.split(' ').includes('horizontal-wrapper') ? {'marginLeft': margin + 'px'} : {margin: '0px'}}>
                        {menus.length > 0 && <ul className="sidebar-links custom-scrollbar">
                            <li className="back-btn">
                                <div className="mobile-back text-right"><span>{"Back"}</span><i
                                    className="fa fa-angle-right pl-2" aria-hidden="true"></i></div>
                            </li>
                            {
                                menus.map((Item, i) =>
                                    <Fragment key={i}>
                                        <li className="sidebar-main-title">
                                            <div>
                                                <h6 className="lan-1">{props.t(Item.menutitle)}</h6>
                                                <p className="lan-2">{props.t(Item.menucontent)}</p>
                                            </div>
                                        </li>
                                        {Item.Items.map((menuItem, i) =>
                                            <li className="sidebar-list" key={i}>
                                                {(menuItem.type === 'sub') ?
                                                    <a href="javascript"
                                                       className={`sidebar-link  sidebar-title ${menuItem.active ? 'active' : ''}`}
                                                       onClick={(event) => {
                                                           event.preventDefault();
                                                           setNavActive(menuItem)
                                                       }}>
                                                        <i className={`${menuItem.icon} mr-2 fa-lg sidebar-icon `} ></i>
                                                        {/*<FontAwesomeIcon icon="fa-solid fa-coffee" size="xs" />*/}
                                                        <span>{props.t(menuItem.title)}</span>
                                                        {menuItem.badge ? <label
                                                            className={menuItem.badge}>{menuItem.badgetxt}</label> : ""}
                                                        <div className="according-menu">
                                                            {menuItem.active ?
                                                                <i className="fa fa-angle-down"></i>
                                                                : <i className="fa fa-angle-right"></i>
                                                            }
                                                        </div>
                                                    </a>
                                                    : ''}

                                                {(menuItem.type === 'link') ?
                                                    <Link to={menuItem.path  }
                                                          className={`sidebar-link sidebar-title link-nav  ${menuItem.active ? 'active' : ''}`}
                                                          onClick={() => setNavActive(menuItem)}>
                                                        <i className={`${menuItem.icon} fa-lg mr-2 sidebar-icon`} />
                                                        <span>{props.t(menuItem.title)}</span>
                                                        {menuItem.badge ? <label
                                                            className={menuItem.badge}>{menuItem.badgetxt}</label> : ""}
                                                    </Link>
                                                    : ''}

                                                {menuItem.children ?

                                                    <ul className="sidebar-submenu"
                                                        style={menuItem.active ? sidebartoogle ? {
                                                            opacity: 1,
                                                            transition: 'opacity 500ms ease-in'
                                                        } : {display: "block"} : {display: "none"}}>

                                                        {menuItem.children.map((childrenItem, index) => {

                                                            return (
                                                                <li key={index}>

                                                                    {(childrenItem.type === 'sub') ?
                                                                        <a href="javascript"
                                                                           className={`${childrenItem.active ? 'active' : ''}`}
                                                                           onClick={(event) => {
                                                                               event.preventDefault();
                                                                               setNavActive(childrenItem);
                                                                           }}>{props.t(childrenItem.title)}
                                                                            <span className="sub-arrow">
                                          <i className="fa fa-chevron-right"></i>
                                        </span>
                                                                            <div className="according-menu">
                                                                                {childrenItem.active ?
                                                                                    <i className="fa fa-angle-down"></i>
                                                                                    :
                                                                                    <i className="fa fa-angle-right"></i>
                                                                                }
                                                                            </div>
                                                                        </a>
                                                                        : ''}

                                                                    {(childrenItem.type === 'link') ?
                                                                        <Link to={childrenItem.path}
                                                                              className={`${childrenItem.active ? 'active' : ''}`}
                                                                              onClick={() => setNavActive(childrenItem)}>{props.t(childrenItem.title)}</Link>
                                                                        : ''}

                                                                    {childrenItem.children ?
                                                                        <ul className="nav-sub-childmenu submenu-content"
                                                                            style={childrenItem.active ? {display: "block"} : {display: "none"}}
                                                                        >
                                                                            {childrenItem.children.map((childrenSubItem, key) =>
                                                                                <li key={key}>
                                                                                    {(childrenSubItem.type === 'link') ?
                                                                                        <Link
                                                                                            to={childrenSubItem.path}
                                                                                            className={`${childrenSubItem.active ? 'active' : ''}`}
                                                                                            onClick={() => setNavActive(childrenSubItem)}>{props.t(childrenSubItem.title)}</Link>
                                                                                        : ''}
                                                                                </li>
                                                                            )}
                                                                        </ul>
                                                                        : ""}

                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                    : ''}
                                            </li>)}
                                    </Fragment>
                                )}
                        </ul>}
                    </div>
                    <div className="right-arrow" onClick={scrollToRight}><ArrowRight/></div>
                </nav>
            </div>
        </Fragment>
    );
}

export default translate(Sidebar);