import * as yup from "yup";
import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {Card, Col, Container, Form, FormGroup, Nav, Row} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import ColoredLine from "../../../../share-components/ui/coloredLine";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import {mapColourSettingToTable, mapSettingResponseToTable} from "../../../../utils/mapper";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import SettingForm from "../settingForm";
import {
    addProductColor,
    closeEditDialog,
    deleteProductColor,
    getAllProductColors,
    openEditDialog,
    openNewDialog,
    selectProductColor,
    updateProductColor
} from "../../store/productColorSlice";
import withReducer from "../../../../store/withReducer";
import productReducer from "../../store";
import PreLoader from "../../../../share-components/ui/preLoader";
import ProductColorForm from "./productColorForm";


const dataMenu = [
    {
        type: 0,
        text: 'Products',
        link: '/product'
    },
    {
        type: 1,
        text: 'Product Setting',
        link: ''
    },
    {
        type: 1,
        text: 'Product Colour',
        link: ''
    }
];

const defaultValues = {
    id: '',
    name: '',
    priority: '',
    tageName: '',
    publish_at: '',
    netsuite_id: '',
};

const schema = yup.object().shape({
    name: yup.string().required('You must enter a color name'),
    netsuite_id: yup.string().required('You must enter a netsuite id'),
});

const ProductColor = () => {
    const dispatch = useDispatch();
    const [name, setName] = useState([]);
    const [loading, setLoading] = useState(true);
    const productColor = useSelector(selectProductColor);
    const [buttonDisable, setButtonDisable] = useState(false);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);

    useEffect(() => {
        const data = {
            name
        };

        dispatch(getAllProductColors({...data})).then(res=> {
            if (!res.error)
            setLoading(false);
        });
    }, [dispatch, name]);

    const tableColumns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            left: true,
        },
        {
            name: 'Netsuite ID',
            selector: row => row.netsuite_id,
            sortable: true,
            left: true,
        },
        {
            name: 'Priority',
            selector: row => row.priority_val,
            sortable: true,
            left: true,
        },
    ];

    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {errors} = formState;

    const onSubmitForm = (dialog, data) => {
        setButtonDisable(true);
        if (dialog.type === 'new') {
            dispatch(addProductColor({...data})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type)
            });
        } else {
            dispatch(updateProductColor({...data})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type);
            });
        }
    }

    const onEdit = (row) => {
        dispatch(openEditDialog(row));
    }

    const onDelete = (row) => {
        dispatch(deleteProductColor(row.id))
    }

    const closeDialog = () => {
        dispatch(closeEditDialog());
    }

    let btnText = '';
    let publishValue = 0;
    let saveValue = 0;
    if (getValues) {
        if (getValues('publish_at') == 1) {
            btnText = 'Save & Unpublish';
            publishValue = 0;
            saveValue = 1;
        } else {
            btnText = 'Save & Publish';
            publishValue = 1;
            saveValue = 0;
        }
    }


    return (
        <Fragment>

            <Container fluid={true} className="product-wrapper">
                {loading ? <><PreLoader></PreLoader></>:<>
                <Breadcrumb data={dataMenu} title="Product Colour"/>
                <div className="product-grid">
                    <div className="feature-products">
                        <Row>
                            <Col sm="12">{(currentUser.permission).includes('Create Colour') ?

                                <Card>
                                    <Row>
                                        <Col sm="6">
                                            <Nav tabs className="border-tab">

                                            </Nav>
                                        </Col>
                                        <Col sm="6">
                                            <div className='float-sm-right p-3'>
                                                <a className="btn btn-sm btn-primary"
                                                   onClick={() => {
                                                       dispatch(openNewDialog())
                                                   }}
                                                > <i className="fa fa-plus"></i> Add Product Colour</a>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                                :''}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="12" sm="12">
                                <Form>
                                    <FormGroup>
                                        <input onChange={(e) => setName(e.target.value)} name="name"
                                               type="text" className="form-control"
                                               placeholder="Search By Name"/>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>

                        <div className="table-responsive product-table mt-4">
                            <CommonDataTable
                                headerColumns={tableColumns}
                                gridData={mapColourSettingToTable(productColor)}
                                pagination
                                onEdit={(currentUser.permission).includes('Update Colour') ? onEdit:''}
                                onDelete={(currentUser.permission).includes('Delete Colour') ? onDelete:''}
                            />
                        </div>

                    </div>
                </div> </>
                }
            </Container>
            <ModalFormSubmit
                defaultValues={defaultValues}
                schema={schema}
                onSubmitForm={onSubmitForm}
                reset={reset}
                formState={formState}
                handleSubmit={handleSubmit}
                title='Product Colour'
                selector='product'
                dialog={({product}) => product.productColor.addDialog}
                closeDialog={closeDialog}
                publish
                setValue={setValue}
                getValues={getValues}
                btnText={btnText}
                publishValue={publishValue}
                saveValue={saveValue}
                buttonDisable={buttonDisable}
            >
                <ProductColorForm
                    control={control} formText="Colour" errors={errors} register={register} setValue={setValue}
                    trigger={trigger} getValues={getValues}
                />
            </ModalFormSubmit>
        </Fragment>
    );
};

export default withReducer([{color: productReducer}])(ProductColor)