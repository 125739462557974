import React,{Fragment, useState} from "react";
import {Col, FormGroup, Label, Row} from "reactstrap";
import Switch from "react-switch";



const ProductColorForm = (props) => {
    const {errors, control, register, setValue, trigger, getValues, setOptions, compositionGroupPromiseOption} = props;
    const [isChecked, setIsChecked] = useState(getValues('priority'));
    const handleChange2 = nextChecked1 => {
        nextChecked1 ? setValue('priority', true) : setValue('priority', false)
        setIsChecked(nextChecked1);
    }
    return (
        <Fragment>
            <Row>
                <Col>
                    <FormGroup>
                        <Label className="col-form-label">Product Color</Label>
                        <input name="name" type="text" {...register('name')}
                               className={`form-control ${errors.name ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.name?.message}</div>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label className="col-form-label">Netsuite ID</Label>
                        <input name="name" type="text" {...register('netsuite_id')}
                               className={`form-control ${errors.netsuite_id ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.netsuite_id?.message}</div>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label style={{marginTop: 8, marginLeft: 4}}>Priority</label>
                    <FormGroup style={{marginTop: 1, marginLeft: 4}}>

                        <Switch
                            checked={isChecked}
                            onChange={handleChange2}
                            uncheckedIcon={false}
                            checkedIcon={false}
                        />

                    </FormGroup>
                </Col>
            </Row>

        </Fragment>
    );
}

export default ProductColorForm;
