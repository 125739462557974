import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    addProductCommunityApi,
    getAllProductCommunityApi
} from "../../../services/manage-product/setting/productCommunityService";
import {
    addProductFitTypeApi, deleteProductFitTypeApi,
    getAllProductFitTypeApi, updateProductFitTypeApi
} from "../../../services/manage-product/setting/productFitTypeService";
import {toast} from "react-toastify";
import {getAllProductCommunities} from "./productCommunitySlice";
import ecommerce from "../../dashboard/ecommerce";


export const getAllProductFitType = createAsyncThunk('productFitType/getProductFitType', async (filters, {dispatch}) => {
    const response = await getAllProductFitTypeApi(filters);
    return response.response;
});

export const addProductFitType = createAsyncThunk('productFitType/addProductFitType', async (fitType, {
    dispatch,
    getState
}) => {
    try {
        const response = await addProductFitTypeApi(fitType);
        const data = await response.response;
        dispatch(getAllProductFitType());
        toast.success("Add product fit type successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const updateProductFitType = createAsyncThunk('productFitType/updateProductFitType', async (fitType, {
    dispatch,
    getState
}) => {
    try {
        const response = await updateProductFitTypeApi(fitType);
        const data = await response.response;
        dispatch(getAllProductFitType());
        toast.success("Updated product fit type successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const deleteProductFitType = createAsyncThunk('productFitType/deleteProductFitType', async (id, {
    dispatch,
    getState
}) => {
    try {
        const response = await deleteProductFitTypeApi(id);
        const data = await response.response;
        dispatch(getAllProductFitType());
        toast.success("Delete product fit type successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

const productFitTypeAdapter = createEntityAdapter({});

export const {selectAll: selectProductFitType, selectById: selectProductFitTypeById} = productFitTypeAdapter.getSelectors(
    state=> state.product.productFitType
);

const productFitTypeSlice = createSlice({
    name: 'fitType',
    initialState: productFitTypeAdapter.getInitialState({
      searchText: '',
      addDialog: {
          component: 'fitType',
          type: 'new',
          props: {
              open: false
          },
          data: null
      },
    }),
    reducers: {
        selectFitTypeSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload
            },
            prepare: event => ({payload: event.target.value || ''})
        },
        openNewDialog: (state, action) => {
            state.addDialog = {
                component: 'fitType',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        openEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: true
                },
                data: action.payload
            };
        },
        closeEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
    },
    extraReducers: {
        [getAllProductFitType.fulfilled]: productFitTypeAdapter.setAll
    }
});

export const {
    selectFitTypeSearchText,
    openNewDialog,
    closeNewDialog,
    openEditDialog,
    closeEditDialog
} = productFitTypeSlice.actions;

export default productFitTypeSlice.reducer;