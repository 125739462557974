import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Form, FormGroup, Input, Row} from "reactstrap";
import {Accordion} from "react-bootstrap";
import "../../../assets/scss/components/accordian.scss";
import AsyncSelect from "react-select/async";
import {mapSearchAutoSuggestion} from "../../../utils/mapper";
import {sellerAutoSuggestionApi, sellerBrandSuggestionApi} from "../../../services/manage-product/productService";
import {useDispatch, useSelector} from "react-redux";

import withReducer from "../../../store/withReducer";
import inventoryReducer from "../store";
import {getInventoryProducts, selectInventory} from "../store/inventorySlice";
import {getProducts} from "../store/prodcutSlice";
import VariationJqx from "../product/edit/content/variationJqx";
import VariationInventoryJqx from "./content/variationInventoryJqx";
import ReactPaginate from "react-paginate";
import PreLoader from "../../../share-components/ui/preLoader";

const dataMenu = [
    {
        type: 0,
        text: 'Inventory',
    }
];
const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

function Inventory() {
    const [currentPage, setCurrentPage] = useState(0);
    const [searchBrand, setSearchBrand] = useState('');
    const [searchName, setSearchName] = useState('');
    const [searchSeller, setSearchSeller] = useState('');
    const [sort, setSort] = useState('id-desc');
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const pageChange = (event) => {
        setCurrentPage(event.selected);
    }
    const sellerPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)))
            });
        });
    const typePromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue,
                seller_id: searchSeller
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(sellerBrandSuggestionApi(data)))
            });
        });
    useEffect(() => {
        dispatch(getInventoryProducts({
            page_size: 8,
            page: currentPage + 1,
            name: searchName,
            brand: searchBrand,
            seller_id: searchSeller,
            sort: sort,
        })).then(res => {
            if (!res.error)
                   setLoading(false);
        })
    }, [dispatch, currentPage, searchName, searchBrand, searchSeller])
    const inventoryState = useSelector(({inventory}) => inventory.inventory);
    const products = useSelector(selectInventory);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);

    return (
        <Fragment>
            {loading ? <>
                    <PreLoader></PreLoader>
                </>
                : <>
            <Breadcrumb title="Inventory" data={dataMenu}/>
            <Container fluid={true} className="product-wrapper">
                <div className="product-grid">
                    <div className="feature-products">
                        <Row>
                            {currentUser.role.id != 5 && <Col xl={3} sm={12}>
                                <AsyncSelect
                                    className="select2-filter"
                                    isClearable={true}
                                    defaultOptions
                                    placeholder="select Seller"
                                    loadOptions={sellerPromiseOption}
                                    styles={customStyles}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    onClear={(e) => {

                                    }}
                                    onChange={(e) => {
                                        setSearchSeller(e ? e.value : null);
                                    }}

                                />
                            </Col>}
                            <Col xl="3" sm="12">

                                <AsyncSelect
                                    className="select2-filter"
                                    isClearable={true}
                                    defaultOptions
                                    placeholder="select Brand"
                                    loadOptions={typePromiseOption}
                                    styles={customStyles}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    onClear={(e) => {

                                    }}
                                    onChange={(e) => {
                                        setSearchBrand(e ? e.value : null);
                                    }}

                                />

                            </Col>
                            <Col xl={currentUser.role.id != 5 ? "6" : "9"} sm="12">
                                <Form>
                                    <FormGroup className="m-0">
                                        <Input
                                            className="form-control"
                                            type="text"
                                            placeholder="search Product Name / Style"
                                            onChange={(e) => {
                                                setSearchName(e.target.value);
                                            }}
                                        />
                                        <i className="fa fa-search"></i>
                                    </FormGroup>
                                </Form>

                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col sm={12}>
                                                <Accordion>
                                                    {products.map((item, i) =>
                                                        <Accordion.Item eventKey={item.id} key={i}>
                                                            <Accordion.Header>
                                                                <div className="image-crop-center" style={{height: 25}}>
                                                                    <img src={item.url} width={25}/>
                                                                </div>
                                                                <div className="ml-1">
                                                                    <span style={{fontWeight: 'lighter', fontSize: 14}}
                                                                          className="mr-5">{item.name} ({item.stock})</span>
                                                                </div>


                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                <VariationInventoryJqx productId={item.id}
                                                                                       sizes={item.size}
                                                                                       variants={item.variants}></VariationInventoryJqx>
                                                            </Accordion.Body>
                                                        </Accordion.Item>)}
                                                </Accordion>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col md={12}>
                                                <ReactPaginate
                                                    className="pagination"
                                                    activeClassName="pagination-active"
                                                    breakLabel="..."
                                                    nextLabel="next >"
                                                    onPageChange={pageChange}
                                                    pageRangeDisplayed={5}
                                                    pageCount={inventoryState.pagination.count}
                                                    initialPage={currentPage}
                                                    previousLabel="< previous"
                                                    renderOnZeroPageCount={null}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>


                        </Row>


                    </div>
                </div>
            </Container>
                </>}
        </Fragment>
    );
}

export default withReducer([{inventory: inventoryReducer}])(Inventory);
;